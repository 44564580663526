<template>
  <div class="vertical-line" :style="isNewLine ? styles.newLine : null">
    <div class="word" ref="word">
      <div class="word-inner" v-if="!isNewLine" :style="styles.word">
        <Pair v-for="(pair, index) in pairs" :key="index"
          :indexes="{ pair: index, ...indexes }" :pair="pair" :type="word[2]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StrToStructureConverter from '@/js/tools/string/str-to-structure-converter.js'
import StrPairinger from '@/js/tools/string/str-pairinger.js'
import Pair from '@/components/documents/document/pages/page/words/word/pair/Pair.vue'
export default {
  name: 'Words',
  components: {
    Pair
  },
  props: {
    indexes: Object,
    word: Array,
    hash: String
  },
  data() {
    return {
      styles: {
        word: {
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          // alignContent: 'flex-start',
          alignContent: 'center',
          justifyContent: 'center',
          padding: null,
        },
        newLine: {
          height: '100%'
        }
      }
    }
  },
  computed: {
    defs() { return this.$store.getters.defs },
    isNewLine() {
      const len = this.word.length
      const main = this.word[0]
      return len === 1
        && typeof(main) === 'object'
        && main.type === StrToStructureConverter.defs.newLine[0].type
    },
    pairs() {
      if (this.word[1]) return StrPairinger.pair(this.word[0], this.word[1])
      return [[this.word[0], this.word[1]]]
    }
  },
  methods: {
    _test() {
      const widthChar = ((this.word||[])[0]||[])[0] || '０'
      const halfChar = String.fromCharCode(widthChar.charCodeAt(0) - 0xFEE0)
      const number = Number(halfChar)
      if (number) this.styles.word.padding = `${number}mm`
    }
  },
  beforeMount() {
    this._test()
  },
  mounted() {
    if (this._oldHash !== this.hash) {
      this.$store.commit('flush', { element: this.$refs.word, color: 'yellow' })
    }
    this._oldHash = this.hash
  },
  beforeUpdate() {
    this._test()
  },
  updated() {
    // wordが更新されたらフラッシュする
    // this.indexesが更新されるのはしょうがない
    // 最後のワードが更新されるのはキャパシティの影響である
    if (this._oldHash !== this.hash) {
      this.$store.commit('flush', { element: this.$refs.word, color: 'yellow' })
    }
    this._oldHash = this.hash
  }
}
</script>

<style lang="css" scoped>
</style>
