<template>
  <h1>スケジュールの編集</h1>
  <div v-for="schedule in schedules" :key="schedule.dateTime">
    <w-flex column class="fill-width">
      <div class="item">
        <router-link :to="`/learn:${schedule.dateTime}`">
          <span v-if="schedule.dateTime <= Date_.now()">
            今日のプリント
          </span>
          <span v-else>
            予定（
            {{ new Date(schedule.dateTime).getMonth()+1 }}月
            {{ new Date(schedule.dateTime).getDate() }}日
            ）のプリント
          </span>
        </router-link>
        <w-button bg-color="error" @click="remove(schedule.dateTime)">削除</w-button>
      </div>
    </w-flex>
  </div>
</template>

<script>
import Common from '@/import/common.js'
const Date = Common.DateTime.ControllableDate
export default {
  computed: {
    Date_() { return Date },
    schedules() { return this.$store.getters['schedule/schedules'] }
  },
  methods: {
    remove(dateTime) {
      this.$store.dispatch('schedule/removeSchedule', dateTime)
    }
  }
}
</script>

<style lang="css" scoped>
h1 {
  text-align: center;
  margin: 3rem;
}
div.item {
  text-align: center;
  font-size: 2rem;
  padding: 1rem;
}
</style>
