<template>
  <w-dialog v-model="show" title="ようこそ ミヒラク☆プリント へ" persistent :width="500">
    <span v-if="!checkedUser">お客様のユーザーを確認しています</span>
    <UserAgreed/>
    <UserSignIn v-if="checkedUser && tmpAgreed"/>
    <UserDialog v-if="requestDialog"/>
  </w-dialog>
</template>

<script>
// https://firebase.google.com/docs/auth/web/manage-users
import {
  getAuth,
  onAuthStateChanged,
  signOut
} from "firebase/auth"
import Back from '@/import/back.js'
import UserAgreed from '@/components/user/UserAgreed.vue'
import UserSignIn from '@/components/user/UserSignIn.vue'
import UserDialog from '@/components/user/UserDialog.vue'
export default {
  components: {
    UserAgreed,
    UserSignIn,
    UserDialog
  },
  data() {
    return {
      show: true,
    }
  },
  computed: {
    checkedUser() { return this.$store.getters['user/checkedUser'] },
    user() { return this.$store.getters['user/user'] },
    tmpAgreed() { return this.$store.getters['user/tmpAgreed'] },
    agreed() { return this.$store.getters['user/agreed'] },
    requestDialog() { return this.$store.getters['user/requestDialog'] }
  },
  watch: {
    user() { this.refresh() },
    agreed() { this.refresh() },
    requestDialog() { this.refresh() }
  },
  mounted() {
    const auth = getAuth()
    // シークレットタブではログインできない不具合（正当？）がある
    // https://github.com/firebase/firebase-js-sdk/issues/3004
    onAuthStateChanged(auth, async user => {
      if (!user) {
        this.$store.commit('user/setNoUser')
      } else {
        const currentUser = getAuth().currentUser
        if (!currentUser) return
        const idToken = await currentUser.getIdToken().catch(error => console.error(error))
        if (!idToken) return
        const result = await Back.BackCommon.fetch('/onsignin', {
          uid: user.uid, idToken, agreed: this.tmpAgreed
        })
        if (result.error) {
          console.error(result.error)
          signOut(auth).then(() => {
            console.log('sign out')
          }).catch(error => {
            console.error(error)
          })
        } else {
          this.$store.commit('user/setUser', user)
          if (this.tmpAgreed) {
            this.$store.dispatch('user/addAgreed', this.tmpAgreed)
            this.$store.dispatch('user/clearTmpAgreed')
          }
        }
      }
    })
  },
  methods: {
    refresh() {
      this.show = !(this.user && this.agreed) || this.requestDialog
    },
  }
}
</script>

<style lang="css" scoped>
</style>
