import Defs from '@/js/defs/defs.js'
import setting from '@/js/store/setting.js'
import SearchStrFromMaps from '@/js/tools/string/search-str-from-maps.js'
import RegExpTools from '@/js/tools/string/reg-exp-tools.js'

export class Document {
  constructor(getters) {
    this.getters = getters
  }
  isPlanPhase() {
    return this.getters.phase === 'plan'
  }
  isDoPhase() {
    return this.getters.phase === 'do'
  }
}
export class Learning {
  constructor(getters) {
    this.getters = getters
  }
  getPurpose(chars, type) {
    const maps = setting.learning.map.purposes
    const p = Defs.struct.word.purposes
    if (type && type.match('書')) return p.write;
    else if (type && type.match('読')) return p.read;
    else {
      const purposes = [p.write, p.read]
      const existences = SearchStrFromMaps.search(
        RegExpTools.removeHiragana(chars), maps[purposes[0]], maps[purposes[1]]
      )
      for (let i = 0; i < purposes.length; i++) {
        const allExist = existences.reduce((total, current) => total & current[i], true)
        if (allExist) return purposes[i]
      }
      return Defs.struct.word.purposes.display
    }
  }
}
export class Chars {
  constructor(getters) {
    this.getters = getters
  }
  hasBracket(purpose, position) {
    return position === Defs.struct.pair.positions.support
      && (purpose === Defs.struct.word.purposes.read
      || purpose === Defs.struct.word.purposes.mean)
  }
  getPaddingTop(purpose, position) {
    return (purpose === Defs.struct.word.purposes.read
      || purpose === Defs.struct.word.purposes.mean)
      && position === Defs.struct.pair.positions.target
      ? setting.chars.size[purpose][position]
      : 0
  }
}
export class Char {
  constructor(getters) {
    this.getters = getters
  }
  isWritable(purpose, position, type) {
    return type === Defs.struct.chars.types.text
      && (purpose === Defs.struct.word.purposes.read
      && position === Defs.struct.pair.positions.support)
      || (purpose === Defs.struct.word.purposes.write
      && position === Defs.struct.pair.positions.target)
  }
  hasRectLine(purpose, position, type) {
    return type === Defs.struct.chars.types.text
      && purpose === Defs.struct.word.purposes.write
      && position === Defs.struct.pair.positions.target
  }
  rotationAngle(type) {
    return (type === Defs.struct.chars.types.prefix
      || type === Defs.struct.chars.types.suffix) ? 90 : 0
  }
}

export default class Designer {
  constructor(getters) {
    this.getters = getters
    this.chars = new Chars(getters)
    this.char = new Char(getters)
  }
}
