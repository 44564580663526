<template>
  <div class="document">
    <Pages/>
  </div>
</template>

<script>
import Pages from '@/components/documents/document/pages/Pages.vue'
import { Rect } from '@/js/tools/dom/selector.js'
export default {
  name: 'Document',
  components: {
    Pages
  },
  computed: {
    finished() { return this.$store.getters['do/finished'] }
  },
  watch: {
    finished(value, old) {
      if (!old && value) this.overview()
    },
  },
  methods: {
    overview() {
      const rect = Rect.toLocalRect(this.$parent.$el, this.$el.getBoundingClientRect())
      this.$store.commit('display/setFocus', this.$el)
      this.$store.commit('display/setFocusArea', rect)
    }
  }
}
</script>

<style lang="css" scoped>
  .document {
    padding: 10mm;
  }
</style>
