<template>
  <div class="pair" :style="style">
    <Chars
      :purpose="purpose" :position="pair[1] ? positions.target : positions.normal" :pair="pair" :chars="pair[0]"
      :indexes="{ chars: 0, ...indexes }"
    />
    <Chars v-if="pair[1]"
      :purpose="purpose" :position="positions.support" :pair="pair" :chars="pair[1]"
      :indexes="{ chars: 1, ...indexes }"
    />
  </div>
</template>

<script>
import Chars from '@/components/documents/document/pages/page/words/word/pair/chars/Chars.vue'
export default {
  components: {
    Chars
  },
  props: {
    indexes: Object,
    pair: Array,
    type: String
  },
  data() {
    return {
    }
  },
  computed: {
    defs() { return this.$store.getters.defs },
    setting() { return this.$store.getters.setting },
    director() { return this.$store.getters.director },
    purposes() { return this.defs.word.purposes },
    positions() { return this.defs.pair.positions },
    purpose() {
      return this.pair[1] ? this.director.learning.getPurpose(this.pair[0], this.type) : this.purposes.display
    },
    marginLeft() {
      if (!this.pair[1]) return 0
      return this.setting.chars.size[this.purpose][this.positions.support]
    },
    style() {
      return {
        display: 'flex',
        justifyContent: 'center',
        marginLeft: this.marginLeft ? `${this.marginLeft}mm` : null
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
