import Updater from '@/js/tools/structure/updater.js'

export default {
  namespaced: true,
  state: {
    pages: [],
    capacities: [0],
    stock: [],
    status: [],
    focus: null,
    focusArea: null
  },
  getters: {
    pages: (state) => state.pages,
    capacities: (state) => state.capacities,
    stock: (state) => state.stock,
    status: (state) => state.status,
    focus: (state) => state.focus,
    focusArea: (state) => state.focusArea
  },
  mutations: {
    clear(state) {
      state.pages = []
      state.capacities = [0]
      state.stock = []
      state.status = []
      state.focus = null
      state.focusArea = null
    },
    setPages(state, pages) {
      Updater.update(state.pages, pages)
    },
    setStock(state, stock) {
      state.stock = stock
    },
    setStatus(state, { index, status }) {
      if (status != null && !(index in state.pages))
        throw new RangeError('Invalid array index.')
      if (status == null) delete state.status[index]
      else state.status[index] = status
    },
    setFocus(state, elm) {
      state.focus = elm
    },
    setFocusArea(state, rect) {
      state.focusArea = rect
    }
  }
}
