<template>
  <Layout/>
</template>

<script>
import Defs from '@/js/defs/defs.js'
import Layout from '@/components/layout/Layout.vue'
export default {
  components: {
    Layout
  },
  beforeMount() {
    this.$store.commit('setPhase', Defs.get('document.phases.plan'))
    this.$store.commit('plan/setText', '（お母さん｜おかあさん）からの（お手紙｜おてがみ）だよ。（今日は｜きょうは）（動物園｜どうぶつえん）とっても（楽しかったね｜たのしかったね）。（象さん｜ぞうさん）が（鼻で｜はなで）（水浴び｜みずあび）していたね。')
  },
  beforeUnmount() {
    this.$store.commit('display/clear')
    this.$store.commit('plan/clear')
    this.$store.commit('setPhase', null)
  },
}
</script>

<style lang="css" scoped>
</style>
