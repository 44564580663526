<template>
  <div class="frame">
    <w-flex class="column">
      <h1>ミヒラク☆プリント</h1>
      <w-flex class="justify-center wrap">
        <w-button @click="$router.push('/learn/schedule')" round bg-color="success">
          学習する
        </w-button>
        <w-button @click="$router.push('/create')" round bg-color="success">
          作成する
        </w-button>
      </w-flex>
    </w-flex>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
div.frame {
  padding: 3rem;
}
h1 {
  margin: 0 auto;
}
button.w-button {
  margin: 2rem;
  padding: 3rem;
  font-size: 2rem;
}
</style>
