<template>
  <div class="char">
    <span class="string" :style="style">{{ char }}</span>
    <WritableBox v-if="isWritable"
      :indexes="indexes" :purpose="purpose" :position="position" :type="type"
      :top="top" :bottom="bottom" :pair="pair" :char="char"
      :learningKeysMap="learningKeysMap"
      @haveSeenAnswer="onHaveSeenAnswer"
    />
  </div>
</template>

<script>
import WritableBox from '@/components/documents/document/pages/page/words/word/pair/chars/char/WritableBox.vue'
export default {
  components: {
    WritableBox
  },
  props: {
    indexes: Object,
    purpose: String,
    position: String,
    type: String,
    top: Boolean,
    bottom: Boolean,
    pair: Array,
    char: String
  },
  data() {
    return {
      haveSeenAnswer: false
    }
  },
  computed: {
    setting() { return this.$store.getters.setting },
    director() { return this.$store.getters.director },
    designer() { return this.$store.getters.designer },
    isPlanPhase() { return this.$store.getters.director.document.isPlanPhase() },
    isWritable() { return this.designer.char.isWritable(this.purpose, this.position, this.type) },

    learningKeysMap() {
      return {
        kanji: this.pair[0],
        yomi: this.pair[1],
        purpose: this.purpose,
        index: this.indexes.char
      }
    },
    isCorrect() {
      return this.$store.getters['do/getResult']({ ...this.learningKeysMap })
    },

    size() { return this.setting.chars.size[this.purpose][this.position] },
    rotationAngle() { return this.designer.char.rotationAngle(this.type) },
    style() {
      return this.isWritable ? {
        fontSize: `${this.size}mm`,
        lineHeight: `${this.size}mm`,
        visibility: (this.isPlanPhase || this.isCorrect || this.haveSeenAnswer) ? 'visible' : 'hidden',
        color: (this.isPlanPhase || !this.isCorrect && this.haveSeenAnswer) ? '#cad3d8' : 'unset',
        zIndex: this.isCorrect ? 1000 : 'unset'
      } : {
        fontSize: `${this.size}mm`,
        lineHeight: `${this.size}mm`,
        transform: this.rotationAngle ? `rotate(${this.rotationAngle}deg)` : null
      }
    }
  },
  methods: {
    onHaveSeenAnswer(value) {
      this.haveSeenAnswer = value
    }
  }
}
</script>

<style lang="scss" scoped>
div.char {
  position: relative; // writing-boxのサイズ引き伸ばすため
  span.string {
    display: inline-block;  // rotateさせるため
    position: relative; // z-indexを制御するため
  }
}
</style>
