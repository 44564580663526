<template>
  <w-toolbar shadow class="page" :style="styles.page.outer">
    <div class="field" ref="field" :style="styles.page.inner">
      <Words :indexes="indexes" :ref="collect" :words="page" @check="onCheck"/>
    </div>
  </w-toolbar>
</template>

<script>
import Words from '@/components/documents/document/pages/page/words/Words.vue'
export default {
  components: {
    Words
  },
  props: {
    indexes: Object,
    page: Array
  },
  data() {
    return {
      children: [],
      styles: {
        page: {
          outer: {
            boxSizing: 'border-box',
            width: '297mm',
            height: '210mm',
            margin: '5mm',
            padding: '15mm',
            backgroundColor: '#fdfdfd'
          },
          inner: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            fontFamily: 'Noto Serif JP',
            // fontFamily: 'Zen Kurenaido',
            fontDisplay: 'block'
          }
        }
      }
    }
  },
  methods: {
    collect(child) {
      if (child) this.children.push(child)
    },
    onCheck() {
      this.$store.commit('display/setStatus', { index: this.indexes.page, status: 'check' })
    }
  },
  beforeUpdate() {
    this.children.length = 0
    this.$store.commit('display/setStatus', { index: this.indexes.page, status: null })
  },
  updated() {
  },
  beforeUnmount() {
    this.$store.commit('display/setStatus', { index: this.indexes.page, status: null })
  }
}
</script>

<style lang="css" scoped>
  .flush {
    /* animation: flush 3s ease 0s infinite normal; */
    animation-name: flush;
    animation-fill-mode: backwards;
    animation-duration: 0.5s;
  }
  @keyframes flush{
    0% { background-color: #dddddd; }
    50% { background-color: red; }
    100% { background-color: #dddddd; }
  }
</style>
