<template>
  <w-button class="retry no-zoom no-select" bg-color="warning" @click="onClick">
    {{ !!countDown ? countDown : 'リトライ' }}
  </w-button>
</template>

<script>
export default {
  props: {
    secToResume: Number
  },
  data() {
    return {
      countDown: this.secToResume
    }
  },
  mounted() {
    if (this.countDown) {
      const timer = setInterval(() => {
        if (--this.countDown <= 0) {
          clearInterval(timer)
        }
      }, 1000)
    }
  },
  methods: {
    onClick() {
      if (this.countDown <= 0) {
        this.$emit('retry')
      } else {
        this.$waveui.notify('カウントがゼロになるまでお待ちいただくか、有料版をご検討ください。', 'warning')
      }
    }
  }
}
</script>

<style lang="css" scoped>
button.retry {
  position: absolute;
  left: 0;
  font-size: xx-small;
  z-index: 200;
  width: 100%;
  height: 100%;
}
</style>
