import Common from '@/import/common.js'
// import FolderStorage from '@/js/tools/storage/folder-storage.js'

const FolderStorage = Common.Storage.FolderStorage

let C
export default class UserFolder {
  static _getStorage(sessionOrLocal) {
    return sessionOrLocal ? sessionStorage : localStorage
  }
  static _selectStorage(isAnonymous) {
    return C._getStorage(isAnonymous)
  }
  static _getFolder(storage, uidHash, folderName) {
    return new FolderStorage(storage, uidHash, folderName)
  }
  constructor(prefix = null, DateClass = null) {
    if (prefix) FolderStorage.registPrefix(prefix)
    if (DateClass) FolderStorage.registDate(DateClass)
  }
  get(user, folderName, sessionOrLocal = null) {
    if (sessionOrLocal == null) sessionOrLocal = user.isAnonymous
    const storage = C._getStorage(sessionOrLocal)
    return C._getFolder(storage, user.uidHash, folderName)
  }
}
C = UserFolder
