<template>
  <w-flex>
    <w-button :disabled="!focusToWritableBox" @click="setSeeAnswer">
      <w-icon>mdi mdi-note-edit-outline</w-icon>
      答えを見る
    </w-button>
    <w-button :disabled="!focusToWritableBox" @click="resetStrokes">
      <w-icon>mdi mdi-eraser</w-icon>
      書き直す
    </w-button>
  </w-flex>

</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    focusElm() {
      return this.$store.getters['display/focus']
    },
    focusToWritableBox() {
      return this.focusElm ? this.focusElm.classList.contains('writable-box') : false
    }
  },
  watch: {
    focusToWritableBox() {
      if (!this.focusToWritableBox) {
        this.$store.commit('do/setSeeAnswer', false)
      }
    }
  },
  beforeUnmount() {
    this.$store.commit('do/setSeeAnswer', false)
  },
  methods: {
    setSeeAnswer() {
      this.$store.commit('do/setSeeAnswer', true)
    },
    resetStrokes() {
      this.$store.commit('do/setRequestResetStrokes', true)
    }
  }
}
</script>

<style lang="scss" scoped>
div.w-flex {
  flex-direction: row-reverse;
  button.w-button {
    margin: 0.1rem;
  }
}
</style>
