import Common from '@/import/common.js'
import LearningNote from '@/js/learning/learning-note.js'
const Date = Common.DateTime.ControllableDate

let C = null
let N = null
export default class LearningBook {
  static initNote(map, kanji, yomi, purpose, index) {
    map = map[kanji] = map[kanji] || {}
    map = map[yomi] = map[yomi] || {}
    map = map[purpose] = map[purpose] || {}
    return map[index] = map[index] || N.createStruct()
  }
  static _getNote(map, kanji, yomi, purpose, index) {
    return (((map[kanji] || {})[yomi] || {})[purpose] || {})[index] || null
  }
  static _searchNotes(map, callback) {
    const kanjiMap = map
    for (const kanji of Object.keys(kanjiMap)) {
      const yomiMap = kanjiMap[kanji]
      for (const yomi of Object.keys(yomiMap)) {
        const purposeMap = yomiMap[yomi]
        for (const purpose of Object.keys(purposeMap)) {
          const indexMap = purposeMap[purpose]
          for (const index of Object.keys(indexMap)) {
            const struct = indexMap[index]
            callback(struct, kanji, yomi, purpose, index)
          }
        }
      }
    }
  }
  static setShow(map, kanji, yomi, purpose, index, value) {
    C.initNote(map, kanji, yomi, purpose, index)
    const note = C._getNote(map, kanji, yomi, purpose, index)
    N.setShow(note, value)
  }
  static setResult(map, kanji, yomi, purpose, index, value) {
    const now = new Date()
    const note = C._getNote(map, kanji, yomi, purpose, index)
    N.setResult(note, now, 20, 4, 2, value)
  }
  static getResult(map, kanji, yomi, purpose, index) {
    const note = C._getNote(map, kanji, yomi, purpose, index)
    if (!note) return null
    return N.getResult(note)
  }
  static getNextLevelDateTime(map, kanji, yomi, purpose, index) {
    const note = C._getNote(map, kanji, yomi, purpose, index)
    if (!note) return null
    return N.getNextLevelDateTime(note)
  }
  static finished(map) {
    let bool = true
    C._searchNotes(map, note => {
      bool &= N.finished(note)
    })
    return !!bool
  }
  static getMinNextLevelDateTime(map) {
    let minTime = null
    C._searchNotes(map, note => {
      const time = N.getNextLevelDateTime(note)
      if (minTime ==null || time < minTime) minTime = time
    })
    return minTime
  }
  static resetAsRelearningAll(map) {
    C._searchNotes(map, note => {
      N.resetResult(note)
      N.resetHaveIncorrect(note)
    })
  }
  static resetAsRelearningOnlyHaveIncorrect(map) {
    C._searchNotes(map, note => {
      if (N.getHaveIncorrect(note)) {
        N.resetResult(note)
        N.resetHaveIncorrect(note)
      }
    })
  }
  static resetAsNextTime(map) {
    C._searchNotes(map, note => {
      N.resetCurrent(note)
      N.resetThisTime(note)
    })
  }
  static setCorrectAsFuture(map) {
    C._searchNotes(map, note => {
      const nextDateTime = N.getNextLevelDateTime(note)
      if (nextDateTime && nextDateTime > Date.now()) {
        N.setCorrect(note)
      }
    })
  }
}
C = LearningBook
N = LearningNote
