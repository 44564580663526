import RegExpTools from '@/js/tools/string/reg-exp-tools.js'

export default class StrPairinger {
  static _splitByKanji(string) {
    if (typeof(string) !== 'string') throw new TypeError('Invalid type.')
    const items = []
    let kanjiOrHira = null, beforeFlug = null
    for (const char of string)  {
      kanjiOrHira = RegExpTools.onlyKanji(char) ? true : false
      if (kanjiOrHira !== beforeFlug) items.push('')
      const i = items.length - 1
      items[i] = `${items[i]}${char}`
      beforeFlug = kanjiOrHira
    }
    return items
  }
  static _matchIsBegin(match) {
    if (match === null) return false
    if (!(match instanceof Array)) throw new TypeError('Invalid type.')
    return match.index <= 0
  }
  static _matchIsEnd(string, match) {
    if (match === null) return false
    if (typeof(string) !== 'string' || !(match instanceof Array))
      throw new TypeError('Invalid type.')
    return match.index + match[0].length >= string.length
  }
  static _searchCharsIndexes(string, chars, beginOrNotBegin = null, endOrNotEnd = null) {
    if (typeof(string) !== 'string' || typeof(chars) !== 'string')
      throw new TypeError('Invalid type.')
    if (string.length <= 0 || chars.length <= 0)
      throw new TypeError('Must be one or more chars.')
    const indexes = []
    const matches = string.matchAll(chars)
    for (const match of matches) {
      if (
        (beginOrNotBegin != null && beginOrNotBegin !== StrPairinger._matchIsBegin(match))
        || (endOrNotEnd != null && endOrNotEnd !== StrPairinger._matchIsEnd(string, match))
      ) continue
      indexes.push(match.index)
    }
    return indexes
  }
  static pair(bun, hurigana) {
    if (typeof(bun) !== 'string' || typeof(hurigana) !== 'string')
      throw new TypeError('Invalid type.')
    if (bun.length <= 0 || hurigana.length <= 0) return [[bun, hurigana]]
    const pairs = []
    const strs = StrPairinger._splitByKanji(bun)
    let beforePair = null
    for (const i in strs) {
      const str = strs[i]
      const pair = [str]
      if (!RegExpTools.onlyKanji(str)) {
        const beforeIsWaiting = beforePair ? beforePair.length <= 1 : false
        const nextIsExisting = i < strs.length - 1
        const indexes = StrPairinger._searchCharsIndexes(
          hurigana, str, !beforeIsWaiting, !nextIsExisting
        )
        if (indexes.length !== 1) return [[bun, hurigana]]
        const index = indexes[0]
        const beforeStr = hurigana.substring(0, index)
        const afterStr = hurigana.substring(index + str.length)
        if (beforeIsWaiting) beforePair.push(beforeStr)
        hurigana = afterStr
      } else if (i >= strs.length - 1) pair.push(hurigana)
      pairs.push(pair)
      beforePair = pair
    }
    return pairs
  }
}
