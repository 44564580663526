import StrForRegExp from '@/js/tools/string/str-for-regexp.js'

export default class RegExpTools {
  static includeKanji(string) {
    if (typeof(string) !== 'string') throw new TypeError('Invalid type.')
    const S = StrForRegExp
    const firstRegExp = new RegExp(S.kanji, 'gu')
    const secondRegExp = new RegExp(S.katakana, 'u')
    const matches = [...string.matchAll(firstRegExp)]
    for (const match of matches) {
      if (!secondRegExp.test(match[0])) return true
    }
    return false
  }
  static onlyHiraganaOrLongSoundOrKutouten(string) {
    if (typeof(string) !== 'string') throw new TypeError('Invalid type.')
    const S = StrForRegExp
    const regExp = new RegExp(
      S.only(S.or(S.hiragana, S._longSound, S._kuten, S._touten)
    ), 'u')
    return regExp.test(string)
  }
  static onlyKanji(string) {
    if (typeof(string) !== 'string') throw new TypeError('Invalid type.')
    const S = StrForRegExp
    const firstRegExp = new RegExp(S.only(S.kanji), 'u')
    const secondRegExp = new RegExp(S.katakana, 'u')
    return firstRegExp.test(string) && !secondRegExp.test(string)
  }
  static onlyHiraganaOrKanjiOrKutouten(string) {
    if (typeof(string) !== 'string') throw new TypeError('Invalid type.')
    const S = StrForRegExp
    const firstRegExp = new RegExp(S.only(S.or(S.hiragana, S.kanji)), 'u')
    const secondRegExp = new RegExp(S.katakana, 'u')
    const string2 = string.replaceAll(new RegExp(S.or(S._kuten, S._touten), 'g'), '')
    return firstRegExp.test(string) && !secondRegExp.test(string2)
  }
  static removeHiragana(string) {
    if (typeof(string) !== 'string') throw new TypeError('Invalid type.')
    const S = StrForRegExp
    const regExp = new RegExp(S.hiragana, 'ug')
    return string.replaceAll(regExp, '')
  }
  static search(string, regExp, foundFunc, otherFunc, callEmpty = false) {
    const matches = string.matchAll(regExp)
    let left = 0
    for (const match of matches) {
      const beforeString = string.substring(left, match.index)
      if (callEmpty || beforeString) otherFunc(beforeString, left)
      const foundString = match[0]
      foundFunc(foundString, match.index)
      left = match.index + foundString.length
    }
    const afterString = string.substring(left)
    if (callEmpty || afterString) otherFunc(afterString, left)
  }
  static wrapOr(args, func) {
    if (!(args instanceof Array) || typeof(func) !== 'function')
      throw new TypeError('Invalid type.')
    if (args.length <= 0) throw new TypeError('Empty array.')
    const regExps = []
    for (const arg of args) {
      const result = func(arg)
      if (typeof(result) !== 'string') throw new TypeError('Invalid type.')
      regExps.push(result)
    }
    return regExps.length === 1 ? regExps[0] : StrForRegExp.or(...regExps)
  }
}
