export default class Selection {
  static _getFiestTextNode(element) {
    const firstChildNode = element.childNodes[0]
    return firstChildNode instanceof Text ? firstChildNode : null
  }
  static _isSelectedOnlyThisElementOrUnderFirstTextNodes(element) {
    const selection = window.getSelection()
    // テキストを入力していない場合は親エレメントがフォーカスされる
    const firstTextOrMyNode = Selection._getFiestTextNode(element) || element
    return firstTextOrMyNode === selection.anchorNode
      && firstTextOrMyNode === selection.focusNode
  }
  static getSelectedStruct(element) {
    if (!Selection._isSelectedOnlyThisElementOrUnderFirstTextNodes(element)) return null
    const selection = window.getSelection()
    const indexes = [selection.anchorOffset, selection.focusOffset]
    const start = Math.min(...indexes)
    const end = Math.max(...indexes)
    const text = element.textContent
    const before = text.substring(0, start)
    const selectionString = text.substring(start, end)
    const after = text.substring(end)
    return { start, end, before, selection: selectionString, after }
  }
  static setSelection(element, start, end) {
    const textNode = Selection._getFiestTextNode(element)
    const range = document.createRange()
    range.setStart(textNode, start)
    range.collapse(true)
    range.setEnd(textNode, end)
    var select = window.getSelection()
    select.removeAllRanges()
    select.addRange(range)
  }
}
