<template>
  <w-dialog v-model="show" persistent>
    <template #title>
      <w-icon class="mr2">mdi mdi-tune</w-icon>
      おつかれさまでした！
    </template>
    <w-flex class="column">
      <p>
        このプリントの次回の学習は
        {{ new Date(nextDateTime).getMonth()+1 }}月
        {{ new Date(nextDateTime).getDate() }}日
        にスケジュールされました。<br>
        次回に備えた学習を行っておきましょう
      </p>
      <p v-if="user.isAnonymous">
        ※ 匿名ユーザーの場合は、ブラウザのタブを閉じるとスケジュールが破棄されます
      </p>
      <w-flex class="justify-center wrap">
        <w-button bg-color="success" @click="relearningOnlyHaveIncorrect">
          直前に間違えた漢字を今再学習しておく
        </w-button>
        <w-button bg-color="info" @click="relearningAll">
          すべての漢字を今再学習しておく
        </w-button>
        <w-button bg-color="warning" @click="end">
          終わりにする
        </w-button>
      </w-flex>
    </w-flex>
  </w-dialog>
</template>

<script>
import LearningBook from '@/js/learning/learning-book.js'
export default {
  data() {
    return {
      show: false,
      nextDateTime: null,
      nextText: null,
      nextLearningBook: null
    }
  },
  computed: {
    user() { return this.$store.getters['user/user'] },
    dateTimeParam() { return this.$route.params.dateTime },
    dateTimeStr() { return this.dateTimeParam ? this.dateTimeParam.replace(/^:/, '') : null },
    dateTime() { return this.dateTimeStr ? Number(this.dateTimeStr) : null },
    finished() { return this.$store.getters['do/finished'] },
    text() { return this.$store.getters['do/text'] },
    learningBook() { return this.$store.getters['do/learningBook'] }
  },
  watch: {
    finished(finished) {
      this.show = finished
      if (finished && !this.nextDateTime) {
        this.createNext()
        this.addStorage()
      }
    }
  },
  methods: {
    createNext() {
      this.nextDateTime = LearningBook.getMinNextLevelDateTime(this.learningBook)
      this.nextLearningBook = JSON.parse(JSON.stringify(this.learningBook))
      LearningBook.resetAsNextTime(this.nextLearningBook)
      this.nextText = this.text
    },
    relearningOnlyHaveIncorrect() {
      LearningBook.resetAsRelearningOnlyHaveIncorrect(this.learningBook)
    },
    relearningAll() {
      LearningBook.resetAsRelearningAll(this.learningBook)
    },
    addStorage() {
      this.$store.dispatch('schedule/addSchedule', {
        text: this.nextText,
        learningBook: this.nextLearningBook,
        dateTime: this.nextDateTime,
        beforeDateTime: this.dateTime
      })
    },
    end() {
      this.show = false
    }
  }
}
</script>

<style lang="css" scoped>
button.w-button {
  margin: 1rem;
  padding: 1rem;
}
</style>
