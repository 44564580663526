<template>
  <w-dialog v-model="showDialog" :title="`${licensedName}ライセンスで ご利用中です`" :width="500">
    <div class="px5">
      <ul>
        <li v-if="licensedType === 'anonymouse'">
          匿名ライセンスでは ブラウザのタブを閉じると 学習履歴等の情報が クリアされます。
          継続して ご利用する際は、お手持ちの Googleアカウントで サインインして ご利用ください。
          <hr>
        </li>
        <li>
          <p class="text-center">
            <a href="https://doc.mihiraku.com/services/print/price" target="_blank" rel="noopener noreferrer">
              ライセンスごとの制限と価格表
            </a>
          </p>
        </li>
        <li>
          <p v-if="licensedType === 'premium'">
            プレミアムライセンスを ご利用いただき ありがとうございます。
          </p>
          <p v-if="['free', 'standard'].includes(licensedType)">
            ライセンスをアップグレードするには 下記のボタンより リクエストしてください。
            サインインしているアカウントのメールアドレス宛にお支払い用のメールをお送りいたします。
            お支払いが 確認できましたら 新しいライセンスの 適用となります。
          </p>
          <p v-if="licensedType === 'anonymouse'">
            ライセンスをアップグレードするには Googleアカウントで サインインしてください。
          </p>
        </li>
        <li>
          <w-button
            class="pa5 fill-width" lg bg-color="success"
            :disabled="sending || !licensedType || ['anonymouse', 'standard', 'premium'].includes(licensedType)"
            @click="onUpgradeToStandard"
          >
            <w-icon class="mr2">mdi mdi-shopping-outline</w-icon>
            標準ライセンスを リクエスト
          </w-button>
        </li>
        <li>
          <w-button
            class="mt3 pa5 fill-width" lg bg-color="primary"
            :disabled="sending || !licensedType || ['anonymouse', 'premium'].includes(licensedType)"
            @click="onUpgradeToPremium"
          >
            <w-icon class="mr2">mdi mdi-shopping-outline</w-icon>
            プレミアムライセンスを リクエスト
          </w-button>
        </li>
        <hr>
        <li>
          <w-button class="pa5 fill-width" lg bg-color="error" @click="onSignOut">
            <w-icon class="mr2">mdi mdi-logout-variant</w-icon>
            サインアウト する
          </w-button>
        </li>
      </ul>
    </div>
  </w-dialog>
</template>

<script>
// https://firebase.google.com/docs/auth/web/manage-users
import {
  getAuth,
  signOut
} from "firebase/auth"
import Back from '@/import/back.js'
export default {
  data() {
    return {
      showDialog: true,
      licensedType: null,
      licensedName: '',
      sending: false
    }
  },
  computed: {
    requestDialog() { return this.$store.getters['user/requestDialog'] }
  },
  watch: {
    requestDialog() { this.refresh() },
    showDialog(showDialog) { if (!showDialog) this.onContinue() },
    licensedType(name) {
      this.licensedName = {
        premium: 'プレミアム',
        standard: '標準',
        free: '無料',
        anonymouse: '匿名',
      }[name]
    }
  },
  async mounted() {
    const user = getAuth().currentUser
    if (!user) return
    const idToken = await user.getIdToken().catch(error => console.error(error))
    if (!idToken) return
    const response = await Back.BackCommon.fetch('/licensed', {
      uid: user.uid, idToken
    })
    if (!response.error) {
      this.licensedType = response.result.name
    }
  },
  methods: {
    refresh() {
      this.showDialog = !this.requestDialog
    },
    onContinue() {
      this.$store.commit('user/setRequestDialog', false)
    },
    onSignOut() {
      const auth = getAuth();
      signOut(auth).then(() => {
        this.$store.commit('user/clear')
        console.log('sign out')
      }).catch(error => {
        console.log(error)
      })
    },
    async onUpgradeToStandard() {
      this.upgrade('standard')
    },
    async onUpgradeToPremium() {
      this.upgrade('premium')
    },
    async upgrade(licenseName) {
      this.sending = false
      const user = getAuth().currentUser
      if (!user) return
      const idToken = await user.getIdToken().catch(error => console.error(error))
      if (!idToken) return
      this.sending = true
      const response = await Back.BackCommon.fetch('/upgrade', {
        uid: user.uid, idToken, request: { license: { name: licenseName } }
      })
      this.sending = false
      if (response.restriction) {
        console.error(response)
        this.$waveui.notify('リクエストが制限されました', 'error')
      } else if (response.error) {
        console.error(response.error)
        this.$waveui.notify('リクエストに失敗しました', 'error')
      } else {
        this.$waveui.notify('リクエストを送信しました', 'success')
      }
    }
  }
}
</script>

<style lang="css" scoped>
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
</style>
