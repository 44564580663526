import {
  createRouter,
  // createWebHashHistory
  createWebHistory
} from 'vue-router'

import Top from '@/components/Top.vue'

import Create from '@/components/phase/Create.vue'
import Import from '@/components/phase/Import.vue'
import Learn from '@/components/phase/Learn.vue'
import Schedule from '@/components/phase/learn/Schedule.vue'
import ModSchedule from '@/components/phase/learn/ModSchedule.vue'
import Daily from '@/components/phase/learn/Daily.vue'

const routes = [
  {
    path: '/',
    component: Top
  }, {
    path: '/create',
    component: Create
  }, {
    path: '/learn:dateTime',
    component: Learn
  }, {
    path: '/learn/schedule',
    component: Schedule
  }, {
    path: '/learn/schedule/mod',
    component: ModSchedule
  }, {
    path: '/learn/import/:data',
    component: Import
  }, {
    path: '/learn/daily',
    component: Daily
  }
]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

export default router
