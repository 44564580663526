export default class LearningNoteStruct {
  static createStruct() {
    return {
      current: {
        show: false,
        result: null
      },
      thisTime: {
        haveIncorrect: false
      },
      history: {
        lastOneShotCorrectDateTime: null
      },
      nextTime: {
        nextLevelDateTime: null
      }
    }
  }
  static getShow(struct) {
    return struct.current.show
  }
  static getResult(struct) {
    return struct.current.result
  }
  static getHaveIncorrect(struct) {
    return struct.thisTime.haveIncorrect
  }
  static getLastOneShotCorrectDateTime(struct) {
    return struct.history.lastOneShotCorrectDateTime
  }
  static getNextLevelDateTime(struct) {
    return struct.nextTime.nextLevelDateTime
  }

  static setShow(struct, value) {
    if (typeof(value) !== 'boolean') {
      throw new TypeError('Invalid type.')
    }
    struct.current.show = value
  }
  static setResult(struct, value) {
    if (!(value === null || typeof(value) === 'boolean')) {
      throw new TypeError('Invalid type.')
    }
    struct.current.result = value
  }
  static setHaveIncorrect(struct, value) {
    if (typeof(value) !== 'boolean') {
      throw new TypeError('Invalid type.')
    }
    struct.thisTime.haveIncorrect = value
  }
  static setLastOneShotCorrectDateTime(struct, value) {
    if (!(value === null || typeof(value) === 'number')) {
      throw new TypeError('Invalid type.')
    }
    struct.history.lastOneShotCorrectDateTime = value
  }
  static setNextLevelDateTime(struct, value) {
    if (!(value === null || typeof(value) === 'number')) {
      throw new TypeError('Invalid type.')
    }
    struct.nextTime.nextLevelDateTime = value
  }
}
