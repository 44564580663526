import Common from '@/import/common.js'
const Date = Common.DateTime.ControllableDate

let C = null
export default class LearningNoteTime {
  // 時を指定を指定した本日の時刻数字を返す
  static _createTodayTime(now, hourNumber) {
    // eslint-disable-next-line no-undef
    if (!(now instanceof globalThis.Date)) throw new TypeError('Invalid type.')
    if (!(typeof(hourNumber) === 'number'
        && Number.isInteger(hourNumber)
        && hourNumber >= 0 && hourNumber < 24)) {
      throw new TypeError('Invalid type.')
    }
    const year = now.getUTCFullYear()
    const month = now.getUTCMonth()
    const date = now.getUTCDate()
    return Date.UTC(year, month, date, hourNumber)
  }
  static calcPlusMinusDays(time, days) {
    if (!(typeof(time) === 'number' && Number.isInteger(time))) {
      throw new TypeError('Invalid type.')
    }
    if (!(typeof(days) === 'number' && Number.isInteger(days))) {
      throw new TypeError('Invalid type.')
    }
    return time + (24 * 60 * 60 * 1000) * days
  }
  /*
    何日後を計算するための基準となる時刻数字を返す
    originHour: 一日の中の起点とするUTC時刻（時：０〜２３）
    minHoursAsDay: 一日として捉える最小の時間数（時：０〜２４）
  */
  static calcBaseOriginTime(now, originHour, minHoursAsDay) {
    const todayOriginTime = C._createTodayTime(now, originHour)
    let [nextOriginTime, beforeOriginTime] = [null, null]
    if (todayOriginTime > now.getTime()) {
      nextOriginTime = todayOriginTime
      beforeOriginTime = C.calcPlusMinusDays(todayOriginTime, -1)
    } else {
      beforeOriginTime = todayOriginTime
      nextOriginTime = C.calcPlusMinusDays(todayOriginTime, 1)
    }
    const minHoursAsDayTime = minHoursAsDay * 60 * 60 * 1000
    if ((nextOriginTime - now.getTime()) >= minHoursAsDayTime) return beforeOriginTime
    else return nextOriginTime
  }
}
C = LearningNoteTime
