export default class ArraysController {
  static collectFromSideAll(arrays, idx, num) {
    if (!(idx in arrays)) throw new RangeError('Invalid array index.')
    if (num < 1)throw new TypeError('Param "num" must be +.')
    const to = arrays[idx]; let len = num
    for (let fi = idx + 1; fi < arrays.length; fi++) {
      if(!(fi in arrays)) break
      const from = arrays[fi]
      const items = from.slice(0, len)
      if (items.length) {
        to.push(...items)
        from.splice(0, len)
        len -= items.length
      }
    }
  }
  static giveToSide(arrays, idx, num) {
    const from = arrays[idx], toIdx = idx + 1, to = arrays[toIdx]
    if (!(idx in arrays) || !(toIdx in arrays)) throw new RangeError('Invalid array index.')
    if (num < 1) throw new TypeError('Param "num" must be +.')
    if (num > from.length) throw new TypeError('Param "num" overflowed.')
    to.unshift(...from.slice(-num))
    from.splice(-num)
  }
}
