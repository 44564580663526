<template>
  <div v-if="!checkedUser">
    <w-spinner fade sm color="green" />
    <w-spinner fade sm color="amber" />
    <w-spinner fade sm color="orange" />
  </div>
  <div v-else-if="!user">
    <span>サインインしてください</span>
  </div>
  <div v-else>
    <span>ようこそ</span>
    <a href="#" @click="onClickedUserName" @touchend="onClickedUserName">
      {{ userName }}
    </a>
    <span>さん</span>
  </div>
</template>

<script>
export default {
  computed: {
    checkedUser() { return this.$store.getters['user/checkedUser'] },
    user() { return this.$store.getters['user/user'] },
    userName() {
      if (!this.user) return ''
      else if (this.user.isAnonymous) return 'ゲスト'
      return this.user.displayName
    }
  },
  methods: {
    onClickedUserName() {
      this.$store.commit('user/setRequestDialog', true)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
