import HashMap from '@/js/tools/structure/hash-map.js'

/*
  NOTE: 配列の並び順序をオブジェクトハッシュを取得して並び替えているが、
  ハッシュが重複しないことが証明されているわけではない
*/
export class ArraySorter {
  static _createHashMap(array) {
    const hashMap = new HashMap()
    array.forEach((item, i) => {
      const hash = HashMap.create(item)
      hashMap.push(hash, { index: i, item })
    })
    return hashMap
  }
  static sort(source, plan) {
    const hashMap = ArraySorter._createHashMap(source)
    for (let i = 0; i < plan.length; i++) {
      const hash = HashMap.create(plan[i])
      if (hashMap.num(hash)) {
        const { index, item } = hashMap.shift(hash)
        if (i !== index) {
          source[i] = item
          if (source[index] === item) source[index] = null
        }
      } else if (!(i in source)) source[i] = null
    }
  }
}

/*
// NOTE: vueのアップデート認識を最小にするために、配列を使い回すアップデーターを作成したが、
確かに、words（恐らくpageも）はアップデートされなくなったが、
結局キーが活用されないため、描画がひとつずつずれ、すべてのwordはアップデートされた
そもそも、キーを活用させたとしても、ワードオブジェクトのインサート処理などは判別できない
よって、本クラスの使用は見送る

ページには使っている
*/
export default class Updater {
  static _isSameType(o1, o2) {
    return ((o1 === null) === (o2 === null))
      && (typeof(o1) === typeof(o2))
      && ((o1 instanceof Array) === (o2 instanceof Array))
  }
  static _isSearchableArray(o) {
    return o instanceof Array
  }
  static _isSearchableObject(o) {
    return o !== null
      && typeof(o) === 'object'
      && !Updater._isSearchableArray(o)
  }
  static _needDeepSearch(base, data) {
    return Updater._isSameType(base, data)
      && (Updater._isSearchableArray(base)
      || Updater._isSearchableObject(base))
  }
  static _needAssign(base, data) {
    return !Updater._needDeepSearch(base, data)
      && (!Updater._isSameType(base, data) || base !== data)
  }
  static _updateArray(baseArray, dataArray) {
    for (let i = 0; i < dataArray.length; i++) {
      const base = baseArray[i]
      const data = dataArray[i]
      if (Updater._needDeepSearch(base, data)) {
        Updater.update(base, data)
      } else if (Updater._needAssign(base, data)) {
        baseArray[i] = data
      }
    }
    baseArray.length = dataArray.length
  }
  static _updateObject(baseObject, dataObject) {
    Object.keys(baseObject).forEach(baseKey => {
      if (!(baseKey in dataObject)) {
        delete baseObject[baseKey]
      }
    })
    Object.keys(dataObject).forEach(dataKey => {
      const data = dataObject[dataKey]
      const base = baseObject[dataKey]
      if (Updater._needDeepSearch(base, data)) {
        Updater.update(base, data)
      } else if (Updater._needAssign(base, data)) {
        baseObject[dataKey] = data
      }
    })
  }
  static update(base, data) {
    if (!Updater._isSameType(base, data)) {
      throw new TypeError('Not same type.')
    }
    if (Updater._isSearchableArray(base)) {
      ArraySorter.sort(base, data)
      Updater._updateArray(base, data)
    } else if (Updater._isSearchableObject(base)) {
      Updater._updateObject(base, data)
    } else {
      throw new TypeError('Unknown type.')
    }
  }
}
