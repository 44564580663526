export default class SearchStrFromMaps {
  static _getAsBool(char, map) {
    if (typeof(char) !== 'string' || typeof(map) !== 'object'
      || map instanceof Array) throw new TypeError('Invalid type.')
    return !!map[char]
  }
  static _collectBools(char, ...maps) {
    const results = []
    for (const map of maps) {
      results.push(SearchStrFromMaps._getAsBool(char, map))
    }
    return results
  }
  static search(chars, ...maps) {
    if (typeof(chars) !== 'string') throw new TypeError('Invalid type.')
    const results = []
    for (const char of chars) {
      results.push(SearchStrFromMaps._collectBools(char, ...maps))
    }
    return results
  }
}
