<template>
  <w-app ref="wapp">
    <div ref="app" class="app">
      <w-card>
        <template #title>
          <w-toolbar shadow>
            <Header/>
          </w-toolbar>
        </template>
        <template #actions>
          <w-toolbar>
            <Footer/>
          </w-toolbar>
        </template>
        <div class="content">
          <router-view/>
        </div>
      </w-card>
    </div>
  </w-app>
</template>

<script>
import { initializeApp } from 'firebase/app'
import Header from '@/components/header/Header.vue'
import Footer from '@/components/footer/Footer.vue'
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      touchedElm: null
    }
  },
  beforeMount() {
    this.initFirebase()
  },
  mounted() {
    document.addEventListener('touchstart', this.cancelDoubleTouchZoom, { capture: true, passive: false })
    document.addEventListener('touchmove', this.cancelPinch, { capture: true, passive: false })
    document.addEventListener('touchmove', this.cancelScroll, { capture: true, passive: false })
    window.addEventListener('scroll', this.alwaysScrollTop, { capture: true })
    window.addEventListener('resize', this.alwaysFitScreen, { capture: true })
  },
  beforeUnmount() {
    document.removeEventListener('touchstart', this.cancelDoubleTouchZoom, { capture: true, passive: false })
    document.removeEventListener('touchmove', this.cancelPinch, { capture: true, passive: false })
    document.removeEventListener('touchmove', this.cancelScroll, { capture: true, passive: false })
    window.removeEventListener('scroll', this.alwaysScrollTop, { capture: true })
    window.removeEventListener('resize', this.alwaysFitScreen, { capture: true })
  },
  methods: {
    initFirebase() {
      initializeApp({
        apiKey: "AIzaSyAQVJHcyKodDH687zIn1WFjOm7JQPLwa78",
        authDomain: "mihiraku-store.firebaseapp.com",
      })
    },
    cancelDoubleTouchZoom(event) {
      const elm = event.targetTouches[0].target
      if (elm === this.touchedElm) {
        event.preventDefault()
      } else {
        this.touchedElm = elm
        setTimeout(() => {
          if (this.touchedElm === elm) {
            this.touchedElm = null
          }
        }, 500)
      }
    },
    cancelPinch(event) {
      if (event.touches.length > 1) {
        event.preventDefault()
      }
    },
    cancelScroll(event) {
      const elm = event.targetTouches[0].target
      if (!elm.closest('.scrollable')) {
        event.preventDefault()
      }
    },
    alwaysScrollTop() {
      window.scroll(0, 0)
    },
    alwaysFitScreen() {
      // TODO: iphoneのfirefoxで縦横を切り替えると下に余白ができる、要対応
      // const menseki = elm => {
      //   const rect = elm.getBoundingClientRect()
      //   return rect.width * rect.height
      // }
      // this.$waveui.notify(menseki(this.$refs.wapp.$el))
      // this.$waveui.notify(menseki(this.$refs.app))
    }
  }
}
</script>

<style>
* {
  /* touch-action: none; スクロールが効かなくなるので使用しない */
}
</style>

<style lang="scss" scoped>
div.app {
  position: relative;
  height: 100%;
}
div.content {
  position: relative;
  height: 100%;
}
</style>

<style lang="scss">
div.w-app {
  min-height: 100%; // 指定しないとvhが指定されてiPhoneのSafariなどでスクロール画面となる
  height: 100%;
  & > div.app {
    & > div.w-card {
      height: 100%;
      & > div.w-card__content {
        position: relative;
        overflow: hidden;
        padding: 0;
      }
      & > div.w-card__title {
        font-size: 1rem;
        & > div.w-toolbar {
          padding: 0;
        }
      }
      & > div.w-card__actions {
        & > div.w-toolbar {
          padding: 0;
        }
      }
    }
  }
}
</style>
