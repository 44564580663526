<template>
  daily
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
