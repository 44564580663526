import { createStore } from 'vuex'
import Defs from '@/js/defs/defs.js'
import setting from '@/js/store/setting.js'
import Director from '@/js/director/director.js'
import Designer from '@/js/director/designer.js'
import UserStore from '@/js/store/sub-stores/user-store.js'
import PlanStore from '@/js/store/sub-stores/plan-store.js'
import DoStore from '@/js/store/sub-stores/do-store.js'
import DisplayStore from '@/js/store/sub-stores/display-store.js'
import UserScheduleStore from '@/js/store/sub-stores/user-schedule-store.js'

export const store = {
  state: {
    setting,
    phase: null,
    // focus: null,
  },
  getters: {
    defs: () => Defs.struct,
    director: (state, getters) => new Director(getters),
    designer: (state, getters) => new Designer(getters),
    setting: (state) => state.setting,
    phase: (state) => state.phase,
    // focus: (state) => state.focus,
  },
  mutations: {
    setPhase(state, phaseName) {
      if (phaseName !== null && !(phaseName in Defs.struct.document.phases)) throw new TypeError('Invalid name.')
      state.phase = phaseName
    },
    flush(state, { element, color = 'yellow' }) {
      const key = '_master_backgroundColor'
      if (!element) return
      if (!(key in element)) element[key] = element.style.backgroundColor
      element.style.backgroundColor = color
      setTimeout(() => element.style.backgroundColor = element[key], 100)
    }
  },
}

export default createStore({
  // strict: process.env.NODE_ENV !== 'production',
  modules: {
    store,
    user: UserStore,
    plan: PlanStore,
    do: DoStore,
    display: DisplayStore,
    schedule: UserScheduleStore
  }
})
