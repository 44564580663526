<template>
  <User/>
  <w-flex class="frame fill-height align-center justify-space-between">
    <router-link to="/">
      Top
    </router-link>
    <div class="user-info">
      <UserInfo/>
    </div>
    <div class="menus">
      <Menus/>
    </div>
  </w-flex>
</template>

<script>
import User from '@/components/user/User.vue'
import UserInfo from '@/components/header/UserInfo.vue'
import Menus from '@/components/header/Menus.vue'
export default {
  components: {
    User,
    UserInfo,
    Menus
  }
}
</script>

<style lang="scss" scoped>
div.frame {
  background-color: #cad3d8;
  color: #2b363b;
  padding: 0 0.5rem;
  div.user-info {
    margin-right: 1rem;
  }
}
</style>
