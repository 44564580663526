import router from '@/js/router/router.js'
import { createApp } from 'vue'
import store from '@/js/store/store.js'

import WaveUI from 'wave-ui'
import 'wave-ui/dist/wave-ui.css'

import '@mdi/font/css/materialdesignicons.min.css'

import App from '@/components/App.vue'
// import Web from '@/components/Web.vue'

const app = createApp(App)
app.use(store).use(router)
new WaveUI(app, {})
app.mount('#app')
