<template>
  <div class="writable-box" :style="style">
    <Canvas
      v-if="showCanvas" ref="canvas" class="canvas" :char="char"
      @mousedown="onTapStart" @touchstart="onTapStart" @mouseup="onTapEnd" @touchend="onTapEnd"
    />
    <Retry v-if="retry" class="retry" :secToResume="secToResume" @retry="onRetry"/>
  </div>
</template>

<script>
import Canvas from '@/components/documents/document/pages/page/words/word/pair/chars/char/Canvas.vue'
import Retry from '@/components/documents/document/pages/page/words/word/pair/chars/char/Retry.vue'
export default {
  components: {
    Canvas,
    Retry
  },
  props: {
    // indexes: Object,
    purpose: String,
    position: String,
    type: String,
    top: Boolean,
    bottom: Boolean,
    pair: Array,
    char: String,
    learningKeysMap: Object
  },
  data() {
    return {
      on: true,
      isFocus: false,
      showCanvas: false,
      isChecking: false,
      canReset: false,
      retry: false,
      secToResume: 0,
      haveSeenAnswer: false
    }
  },
  computed: {
    setting() { return this.$store.getters.setting },
    director() { return this.$store.getters.director },
    designer() { return this.$store.getters.designer },
    isDoPhase() { return this.$store.getters.director.document.isDoPhase() },

    focus() { return this.$store.getters['display/focus'] },
    isCorrect() {
      return this.$store.getters['do/getResult'](this.learningKeysMap)
    },
    seeAnswer() {
      return this.$store.getters['do/seeAnswer']
    },
    requestResetStrokes() {
      return this.$store.getters['do/requestResetStrokes']
    },

    hasRectLine() { return this.designer.char.hasRectLine(this.purpose, this.position, this.type) },
    style() {
      return {
        borderRight: this.hasRectLine ? 'solid' : null,
        borderLeft: this.hasRectLine ? 'solid' : null,
        borderTop: (this.hasRectLine && this.top) ? 'solid' : null,
        borderBottom: this.hasRectLine ? (this.bottom ? 'solid' : 'dotted') : null,
        backgroundColor: (() => {
          if (this.isCorrect === false) return '#ffa5a5'
          if (this.isCorrect === true) return '#8defff'
          if (this.isChecking) return 'unset'
          if (this.retry) return '#cad3d8'
          if (this.isFocus || this.haveSeenAnswer) return 'unset'
          return null
        })()
      }
    }
  },
  watch: {
    async focus(focusElm, beforeFocusElm) {
      if (focusElm === this.$el || beforeFocusElm === this.$el) {
        if (focusElm === this.$el) this.onFocus()
        else this.onUnFocus()
      }
    },
    seeAnswer() {
      if (this.seeAnswer && this.isFocus) {
        if (this.canReset) this.reset()
        this.haveSeenAnswer = true
        this.$emit('haveSeenAnswer', true)
      }
    },
    requestResetStrokes() {
      if (this.isFocus && !this.isChecking && !this.retry && this.requestResetStrokes) {
        if (this.canReset) this.reset()
        else if (this.$refs.canvas) this.$refs.canvas.reset()
        this.$store.commit('do/setRequestResetStrokes', false)
      }
    }
  },
  mounted() {
    if (!this.isDoPhase) return
    this.$store.commit('do/setShow', this.learningKeysMap)
  },
  methods: {
    onFocus() {
      if (!this.isDoPhase) return
      if (this.isChecking || this.retry) return
      if (!this.showCanvas && this.isCorrect == null) {
        this.showCanvas = true
      } else {
        if (this.isCorrect == null) this.$refs.canvas.start()
        else if (this.isCorrect === false) this.canReset = true
      }
      this.isFocus = true
    },
    async onUnFocus() {
      if (!this.isDoPhase) return
      if (this.isChecking || this.retry) return
      if (this.showCanvas && this.isCorrect == null) {
        this.$refs.canvas.pause()
        if (!this.$refs.canvas.isEmpty()) await this.detect()
      }
      this.isFocus = false
      this.canReset = false
    },
    onTapStart(event) {
      if (!this.isDoPhase) return
      if (this.retry) return
      if (this.canReset) {
        if (event instanceof MouseEvent && event.button === 0
            || event instanceof TouchEvent && event.touches.length === 1) {
          // リセット待ちのタップ開始はSelectorやZoomに処理を届けない
          event.stopPropagation()
        }
      }
    },
    onTapEnd(event) {
      if (!this.isDoPhase) return
      if (this.isChecking || this.retry) return
      // タップ終了時にリセット処理することでHandWriterに不要なイベントを届けない
      if (this.canReset) {
        if (event instanceof MouseEvent && event.button === 0
            || event instanceof TouchEvent && event.touches.length === 1) {
          // リセット待ちのタップ終了はSelectorやZoomに処理を届けない
          event.stopPropagation()
          this.reset()
        }
      }
    },
    reset() {
      this.$store.commit('do/setResult', {
        ...this.learningKeysMap,
        result: null
      })
      this.canReset = false
      this.$refs.canvas.reset()
      this.$refs.canvas.start()
    },
    onRetry() {
      if (!this.isDoPhase) return
      if (this.isChecking) return
      this.retry = false
      this.detect()
    },
    async detect() {
      if (!this.isDoPhase) return
      this.isChecking = true
      const result = await this.$refs.canvas.detect()
      this.isChecking = false
      if  (result.empty) {
        // not process
      } else if (result.error) {
        console.error(result.error)
        this.$waveui.notify(`${result.error}`)
        this.retry = true
      } else if (result.restriction) {
        this.retry = true
        if (result.resumeAt) {
          this.secToResume = Math.floor((result.resumeAt - Date.now()) / 1000) + 1
        }
        result.resumeAt ||= Date.now()
      } else {
        const isCorrect = result.chars.includes(this.char)
        if (isCorrect && this.haveSeenAnswer) {
          this.$store.commit('do/setResult', {
            ...this.learningKeysMap,
            result: false
          })
          this.haveSeenAnswer = false
          this.$emit('haveSeenAnswer', false)
        }
        this.$store.commit('do/setResult', {
          ...this.learningKeysMap,
          result: isCorrect
        })
        if (isCorrect) this.showCanvas = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div.writable-box {
  position: absolute; // 文字要素と重ね、キャンパス要素の起点とするため
  top: 0;
  height: 100%;
  width: 100%;
  div.canvas {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .retry {
    z-index: 3000;
  }
}
</style>
