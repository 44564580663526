<template>
  <div class="words" ref="words" :style="styles.words">
    <Word v-for="(word, index) in words" :key="keys[index]" :ref="collect"
      :indexes="{ word: index, ...indexes }" :word="word" :hash="keys[index]"
    />
  </div>
</template>
<script>
/* NOTE:
flexboxで、縦に並べながら、横に改行したとしても、ラッパーの大きさはフィットしない
そのため、段落が異なったとしてもラッパーはひとつとし、改行用のdiv（幅0、高さ100%）を追加するものとする
https://stackoverflow.com/questions/37406353/make-container-shrink-to-fit-child-elements-as-they-wrap
https://dezanari.com/css-flexbox-intended-wrap/
*/
import HashMap from '@/js/tools/structure/hash-map.js'
import Word from '@/components/documents/document/pages/page/words/word/Word.vue'
export default {
  components: {
    Word
  },
  props: {
    indexes: Object,
    words: Array
  },
  data() {
    return {
      children: [],
      keys: [],
      styles: {
        words: {
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap-reverse',
          alignContent: 'flex-start',
          // NOTE: ストレッチ指定とすることでオーバーフロー確認を行っている
          alignItems: 'stretch'
        },
        ender: {
          height: '1px'
        }
      }
    }
  },
  methods: {
    createKeys() {
      this.keys.length = 0
      const map = new HashMap()
      this.words.forEach((word, index) => {
        const hash = HashMap.create(word)
        const num = map.num(hash)
        const key = `${hash}_${num}`
        map.push(hash)
        this.keys[index] = key
      })
    },
    collect(child) {
      if (child) this.children.push(child)
    },
    check() {
      this.$emit('check')
    }
  },
  beforeMount() {
    this.createKeys()
  },
  mounted() {
    // console.log('words mounted')
    this.check()
    this.$store.commit('flush', { element: this.$refs.words.parentNode, color: 'white' })
  },
  beforeUpdate() {
    this.children.length = 0
    this.createKeys()
  },
  updated() {
    // console.log('words updated')
    this.check()
    this.$store.commit('flush', { element: this.$refs.words.parentNode, color: 'white' })
  },
}
</script>

<style lang="css" scoped>
</style>
