import StrForRegExp from '@/js/tools/string/str-for-regexp.js'
import RegExpTools from '@/js/tools/string/reg-exp-tools.js'

export default class StrToStructureConverter {
  static defs = {
    newLine: [{ type: 'new-line' }]
  }
  static _clear(object) {
    object.structure = []
    object.history = {
      indexes: {
        brackets: { start: [], end: [] },
        separators: [],
        newLines: []
      }
    }
    return object
  }
  static _createBundleEscapeRegExp(escapeChars) {
    if (typeof(escapeChars) !== 'string') throw new TypeError('Invalid type.')
    if (escapeChars.length < 1) throw new TypeError('Must be one or more chars.')
    const charArray = escapeChars.split('')
    const multi = RegExpTools.wrapOr(charArray, char => {
      return StrForRegExp.excludeAfter(char, char)
    })
    return new RegExp(multi, 'g')
  }
  static _createEscapeRegExp(chars, escapeChars) {
    if (typeof(chars) !== 'string' || typeof(escapeChars) !== 'string')
      throw new TypeError('Invalid type.')
    if (chars.length < 1 || escapeChars.length < 1)
      throw new TypeError('Must be one or more chars.')
    const charArray = chars.split('')
    const multi = RegExpTools.wrapOr(charArray, char => {
      return StrForRegExp.excludeAfter(char, escapeChars)
    })
    return new RegExp(multi, 'g')
  }
  static _createBracketsRegExp(chars, escapeChars) {
    if (typeof(chars) !== 'string' || typeof(escapeChars) !== 'string')
      throw new TypeError('Invalid type.')
    if (!chars.length || chars.length % 2 !== 0)
      throw new TypeError('Must be two or more even chars.')
    if (escapeChars.length < 1) throw new TypeError('Must be one or more chars.')
    const charPairs = chars.match(/.{2}/g);
    const multi = RegExpTools.wrapOr(charPairs, charPair => {
      return StrForRegExp.betweenBrackets(charPair[0], charPair[1], escapeChars)
    })
    return new RegExp(multi, 'g')
  }
  static _createRegExps(setting) {
    const C = StrToStructureConverter
    const s = setting
    return {
      bundleEscape: C._createBundleEscapeRegExp(s.escapeChars),
      newLine: C._createEscapeRegExp(s.newLineChars, s.escapeChars),
      separator: C._createEscapeRegExp(s.separatorChars, s.escapeChars),
      brackets: C._createBracketsRegExp(s.bracketChars, s.escapeChars)
    }
  }
  constructor(setting = null) {
    this.structure = null
    this.history = null
    this.regExps = {
      bundleEscape: null,
      newLine: null,
      separator: null,
      brackets: null
    }
    if (setting) this.setChars(setting)
  }
  setChars(setting) {
    const regExps = StrToStructureConverter._createRegExps(setting)
    Object.assign(this.regExps, regExps)
  }
  _bundleEscape(subString) {
    if (!this.regExps.bundleEscape) throw new ReferenceError('Not defined.')
    return subString.replaceAll(this.regExps.bundleEscape, '')
  }
  _analyzeNewLines(stringD1, indexD0) {
    if (!this.regExps.newLine) throw new ReferenceError('Not defined.')
    RegExpTools.search(stringD1, this.regExps.newLine,
      (stringD2, indexD1) => {
        this.history.indexes.newLines.push(indexD0 + indexD1)
        this.structure.push(StrToStructureConverter.defs.newLine)
      },
      (stringD2) => {
        const arrays = this._bundleEscape(stringD2).split('').map(c => [c])
        this.structure.push(...arrays)
    })
  }
  _analyzeSeparators(stringD1) {
    if (!this.regExps.separator) throw new ReferenceError('Not defined.')
    const subStrings = []
    const indexes = []
    RegExpTools.search(stringD1, this.regExps.separator,
      (stringD2, indexD1) => {
        indexes.push(indexD1)
      },
      (stringD2) => {
        subStrings.push(this._bundleEscape(stringD2))
    }, true)
    this.history.indexes.separators.push(indexes)
    this.structure.push(subStrings)
  }
  _analyzeBrackets(string) {
    if (typeof(string) !== 'string') throw new TypeError('Invalid type.')
    if (!this.regExps.brackets) throw new ReferenceError('Not defined.')
    RegExpTools.search(string, this.regExps.brackets,
      (stringD1, indexD0) => {
        this.history.indexes.brackets.start.push(indexD0)
        this.history.indexes.brackets.end.push(indexD0 + stringD1.length - 1)
        this._analyzeSeparators(stringD1.substring(1, stringD1.length - 1))
    }, this._analyzeNewLines.bind(this))
  }
  convert(string) {
    if (typeof(string) !== 'string') throw new TypeError('Invalid type.')
    StrToStructureConverter._clear(this)
    this._analyzeBrackets(string)
    return { structure: this.structure, history: this.history }
  }
  isIncludingBracket(startIndex, endIndex) {
    if (typeof(startIndex) !== 'number' || typeof(endIndex) !== 'number')
      throw new TypeError('Invalid type.')
    if (startIndex < 0 || endIndex < 0) throw new TypeError('Must be plus value.')
    if (startIndex > endIndex) throw new TypeError('Must be equal or greater.')
    const history = this.history.indexes.brackets
    for (let i = 0; i < history.start.length; i++) {
      const bs = history.start[i]
      const be = history.end[i]
      if (bs < startIndex && startIndex <= be
          || bs < endIndex && endIndex <= be
          || (startIndex <= bs && be < endIndex )) return true
      if (startIndex <= be) break
    }
    return false
  }
}
