<template>
  <div
    oncontextmenu="return false;"
    ref="documents" class="documents" :style="style"
    @mousedown.capture="onOpeStart" @touchstart.capture="onOpeStart"
    @mouseup="onOpeEnd" @touchend="onOpeEnd"
  >
    <Document/>
  </div>
</template>

<script>
import Zoomer from '@/js/tools/dom/zoomer.js'
import Selector from '@/js/tools/dom/selector.js'
import Document from '@/components/documents/document/Document.vue'
export default {
  name: 'Documents',
  components: {
    Document
  },
  data() {
    return {
      zoomer: null,
      stockedPageNum: null,
      style: {
        userSelect: 'none',
      }
    }
  },
  computed: {
    pages() { return this.$store.getters['display/pages'] },
    pages_() { return this.$store.getters['display/pages'] },
    focusArea() { return this.$store.getters['display/focusArea'] }
  },
  watch: {
    // pages(...args) { this.check(...args) },
    pages_: {
      deep: true,
      handler(...args) { this.check(...args) }
    },
    focusArea(rect) {
      this.zoomer.fit(rect)
    }
  },
  methods: {
    check(pages) {
      if (pages.length !== this.stockedPageNum) {
        this.stockedPageNum = pages.length
        this.$nextTick(() => {
          this.fitDocuments()
        })
      }
    },
    fitDocuments() {
      this.selector.zoomOutToField()
    },
    onOpeStart(event) {
      if (event.target.classList.contains('no-zoom')
          || event.target.closest('.no-zoom')) {
        this.zoomer.pause()
      }
      if (event.target.classList.contains('no-select')
          || event.target.closest('.no-select')) {
        this.selector.pause()
      }
    },
    onOpeEnd(event) {
      if (event.target.classList.contains('no-zoom')
          || event.target.closest('.no-zoom')) {
        this.zoomer.resume()
      }
      if (event.target.classList.contains('no-select')
          || event.target.closest('.no-select')) {
        this.selector.resume()
      }
    }
  },
  mounted() {
    this.zoomer = new Zoomer(this.$refs.documents)
    this.selector = new Selector(
      this.$refs.documents.parentElement,
      this.$refs.documents,
      {
        documents: true,
        page: true,
        'vertical-line': {
          rect: elm => {
            const pageElm = elm.closest('.field')
            const pageRect = pageElm.getBoundingClientRect()
            const rect = elm.getBoundingClientRect()
            return {
              left: rect.left,
              top: pageRect.top,
              width: rect.width,
              height: pageRect.height,
            }
          }
        },
        word: {
          check: elm => elm.firstElementChild.childElementCount > 1
        },
        pair: true,
        'writable-box': {
          // check: elm => elm.classList.contains('writable'),
          rect: elm => {
            const pairElm = elm.closest('.pair')
            const pairRect = pairElm.getBoundingClientRect()
            const rect = elm.getBoundingClientRect()
            return {
              left: pairRect.left,
              top: rect.top,
              width: pairRect.width,
              height: rect.height
            }
          }
        },
      },
      data => {
        // console.log(data.elm)
        this.$store.commit('display/setFocus', data.elm)
        // this.zoomer.fit(data.rect)
        this.$store.commit('display/setFocusArea', data.rect)
      },
      this.$waveui
    )
  }
}
</script>

<style lang="css" scoped>
  .documents {
    position: absolute;
    display: flex;
  }
</style>
