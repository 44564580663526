<template>
  <w-menu class="frame" bottom align-right>
    <template #activator="{ on }">
      <w-button v-on="on" icon="mdi mdi-menu" bg-color="transparent"></w-button>
    </template>
    <PlanMenus v-if="isPlanPhase"/>
    <DoMenus v-if="isDoPhase"/>
  </w-menu>
</template>

<script>
import PlanMenus from '@/components/header/PlanMenus'
import DoMenus from '@/components/header/DoMenus'
export default {
  components: {
    PlanMenus,
    DoMenus
  },
  computed: {
    isPlanPhase() { return this.$store.getters.director.document.isPlanPhase() },
    isDoPhase() { return this.$store.getters.director.document.isDoPhase() }
  }
}
</script>

<style lang="css" scoped>
</style>
