import LearningBook from '@/js/learning/learning-book.js'

export default {
  namespaced: true,
  state: {
    text: null,
    structure: null,
    learningBook: {},
    seeAnswer: false,
    requestResetStrokes: false
  },
  getters: {
    text: (state) => state.text,
    structure: (state) => state.structure,
    learningBook: (state) => state.learningBook,
    getResult(state) {
      return ({ kanji, yomi, purpose, index }) => {
        return LearningBook.getResult(state.learningBook, kanji, yomi, purpose, index)
      }
    },
    finished: (state) => LearningBook.finished(state.learningBook),
    seeAnswer(state) {
      return state.seeAnswer
    },
    requestResetStrokes(state) {
      return state.requestResetStrokes
    }
  },
  mutations: {
    clear(state) {
      state.text = null
      state.structure = null
      state.learningBook = {}
    },
    setText(state, text) { state.text = text },
    setStructure(state, structure) { state.structure = structure },
    setLearningBook(state, learningBook) { state.learningBook = learningBook },
    setShow(state, { kanji, yomi, purpose, index }) {
      LearningBook.setShow(state.learningBook, kanji, yomi, purpose, index, true)
    },
    setResult(state, { kanji, yomi, purpose, index, result }) {
      LearningBook.setResult(state.learningBook, kanji, yomi, purpose, index, result)
    },
    setSeeAnswer(state, value) {
      state.seeAnswer = value
    },
    setRequestResetStrokes(state, value) {
      state.requestResetStrokes = value
    }
  },
  actions: {
  }
}
