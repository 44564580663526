<template>
  <w-dialog class="pa5" v-model="show" persistent :width="500" transition="slide-fade-down">
    <template #title>
      <w-icon class="mr2">mdi mdi-login-variant</w-icon>
      サインインしてご利用下さい
    </template>
    <ul>
      <li>
        <w-button class="pa5 fill-width" lg bg-color="primary" @click="onSignInAsGoogle" :disabled="isProcessing">
          <w-icon class="mr2">mdi mdi-google</w-icon>
          Google アカウント で サインイン
        </w-button>
      </li>
      <li>
        <w-button class="mt3 pa5 fill-width" lg bg-color="secondary" @click="onSignInAsAnonymous" :disabled="isProcessing">
          <w-icon class="mr2">mdi mdi-incognito-circle</w-icon>
          匿名 アカウント で サインイン
        </w-button>
      </li>
    </ul>
  </w-dialog>
</template>
<script>
// https://firebase.google.com/docs/auth/web/manage-users
import {
  GoogleAuthProvider,
  getAuth,
  signInWithRedirect,
  signInAnonymously,
} from "firebase/auth"
export default {
  data() {
    return {
      show: true,
      isProcessing: false,
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onSignInAsGoogle() {
      this.isProcessing = true
      const provider = new GoogleAuthProvider()
      const auth = getAuth();
      signInWithRedirect(auth, provider)
      .catch(error => {
        console.error(error)
      });
    },
    onSignInAsAnonymous() {
      this.isProcessing = true
      const auth = getAuth();
      signInAnonymously(auth)
      .catch(error => {
        console.error(error)
      });
    },
  }
}
</script>

<style lang="css" scoped>
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
</style>
