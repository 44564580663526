<template>
  <Actioner/>
  <!-- <splitpanes horizontal>
    <pane :style="styles.panes.header">
      <Header/>
    </pane>
    <pane :style="styles.panes.main"> -->
      <splitpanes :horizontal="style.layout.horizontal" ref="layout" class="default-theme" @resized="onPaneResized">
        <pane ref="control" :size="isPlanPhase ? 30 : 0" :max-size="isPlanPhase ? null : 0" min-size="10"
          :style="styles.panes.control"
        >
          <TextHolder ref="editor"/>
        </pane>
        <pane ref="display" :size="isPlanPhase ? 70 : 100" min-size="10">
          <div :style="styles.panes.documents">
            <Documents ref="documents"/>
          </div>
        </pane>
      </splitpanes>
    <!-- </pane>
    <pane :style="styles.panes.footer">
      <Footer/>
    </pane>
  </splitpanes> -->
</template>

<script>
import { Splitpanes, Pane } from 'splitpanes'
import '@/css/splitpanes.css'
// import Header from '@/components/header/Header.vue'
// import Footer from '@/components/Footer.vue'
import Documents from '@/components/documents/Documents.vue'
import TextHolder from '@/components/layout/TextHolder.vue'
import Actioner from '@/components/Actioner.vue'
export default {
  name: 'Layout',
  components: {
    Splitpanes,
    Pane,
    // Header,
    // Footer,
    Documents,
    TextHolder,
    Actioner
  },
  data() {
    return {
      style: {
        header: {
          height: '10mm'
        },
        footer: {
          height: '10mm'
        },
        layout: {
          horizontal: false,
        }
      }
    }
  },
  computed: {
    isPlanPhase() { return this.$store.getters.director.document.isPlanPhase() },
    styles() {
      return {
        panes: {
          header: {
            height: this.style.header.height,
            backgroundColor: '#e3f2fd'
          },
          footer: {
            height: this.style.footer.height,
            backgroundColor: '#e3f2fd'
          },
          main: {
            height: this.isPlanPhase ? `calc(100% - ${this.style.header.height} - ${this.style.footer.height})` : '100%'
          },
          control: {
            overflowY: 'auto',
          },
          documents: {
            width: '100%',
            height: '100%',
            position: 'relative',
            background: '#eeeeee',
            backgroundImage: 'radial-gradient(#e5e5e5 30%, transparent 0), radial-gradient(#e5e5e5 30%, transparent 0)',
            backgroundSize: '30px 30px',
            backgroundPosition: '0 0, 15px 15px',
          }
        },
      }
    }
  },
  mounted() {
    // document.addEventListener('touchmove', this.cancelTouchZoom, { capture: true, passive: false })
    // document.addEventListener('mousewheel', this.cancel, { capture: true, passive: false })
    window.addEventListener('resize', this.onResize)
    this.fitDirection()
  },
  beforeUnmount() {
    // document.removeEventListener('touchmove', this.cancelTouchZoom, { capture: true, passive: false })
    // document.removeEventListener('mousewheel', this.cancel, { capture: true, passive: false })
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    // cancelTouchZoom(event) {
    //   if (event.touches.length > 1) {
    //     event.preventDefault()
    //   }
    // },
    fitDirection() {
      this.style.layout.horizontal = window.innerWidth < window.innerHeight
    },
    fitZoom() {
      this.$refs.documents.fitDocuments()
    },
    onResize() {
      this.fitDirection()
      requestAnimationFrame(this.fitZoom)
    },
    onPaneResized() {
      requestAnimationFrame(this.fitZoom)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
