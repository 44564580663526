<template>
  <div class="chars" :style="style">
    <Char v-if="hasBracket"
      :purpose="purpose" :position="position" :type="types.prefix" :char="'（'"
    />
    <Char v-for="(char, index) in chars" :key="index"
      :purpose="purpose" :position="position" :type="types.text"
      :top="index === 0" :bottom="index === chars.length - 1"
      :pair="pair" :char="char" :indexes="{ char: index, ...indexes }"
    />
    <Char v-if="hasBracket"
      :purpose="purpose" :position="position" :type="types.suffix" :char="'）'"
    />
  </div>
</template>

<script>
import Char from '@/components/documents/document/pages/page/words/word/pair/chars/char/Char.vue'
export default {
  components: {
    Char
  },
  props: {
    indexes: Object,
    purpose: String,
    position: String,
    pair: Array,
    chars: String,
  },
  data() {
    return {
    }
  },
  computed: {
    defs() { return this.$store.getters.defs },
    designer() { return this.$store.getters.designer },
    purposes() { return this.defs.word.purposes },
    types() { return this.defs.chars.types },
    hasBracket() { return this.designer.chars.hasBracket(this.purpose, this.position) },
    paddingTop() { return this.designer.chars.getPaddingTop(this.purpose, this.position) },
    style() {
      return {
        paddingTop: this.paddingTop ? `${this.paddingTop}mm` : null,
      }
    }
  }
}
</script>

<style lang="css" scoped>
  .chars {
    display:flex;
    flex-direction: column;
    /* background-color: #bbbbbb; */
  }
</style>
