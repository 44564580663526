<template>
  <w-flex>
    <router-link :to="doUrl" :target="isAnonymousUser ? '_self' : '_blank'">
      <w-button>
        <w-icon>mdi mdi-note-edit-outline</w-icon>
        学習ページへ
      </w-button>
    </router-link>
    <!-- <w-button :route="doUrl" target="_blank" rel="noopener noreferrer">
      <w-icon>mdi mdi-note-edit-outline</w-icon>
      学習ページへ
    </w-button> -->
  </w-flex>
</template>

<script>
import lzutf8 from 'lzutf8'
export default {
  computed: {
    user() { return this.$store.getters['user/user'] },
    isAnonymousUser() { return this.user ? this.user.isAnonymous : null },
    doUrl() {
      const text = this.$store.getters['plan/text']
      if (!text) return null
      const compressed = lzutf8.compress(text, { outputEncoding: 'Base64' })
      const encoded = encodeURIComponent(compressed)
      return `/learn/import/${encoded}`
    }
  }
}
</script>

<style lang="css" scoped>
</style>
