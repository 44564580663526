<template>
  <Layout/>
  <w-dialog
    v-model="showOverlap"
    persistent
  >
    <p>同じプリントがスケジュールに登録されています</p>
    <w-button bg-color="primary" @click="showOverlap = false">
      OK
    </w-button>
  </w-dialog>
</template>

<script>
import objectHash from 'object-hash'
import lzutf8 from 'lzutf8'
import Defs from '@/js/defs/defs.js'
import Layout from '@/components/layout/Layout.vue'
export default {
  components: {
    Layout
  },
  data() {
    return {
      printHash: null,
      showOverlap: false
    }
  },
  computed: {
    user() { return this.$store.getters['user/user'] },
  },
  watch: {
    user() {
      if (this.user) this.checkPrintHash()
    }
  },
  beforeMount() {
    this.getText()
    this.$store.commit('setPhase', Defs.get('document.phases.do'))
  },
  mounted() {
    if (this.user) this.checkPrintHash()
  },
  beforeUnmount() {
    this.$store.commit('display/clear')
    this.$store.commit('do/clear')
    this.$store.commit('setPhase', null)
  },
  methods: {
    getText() {
      let text = ''
      try {
        const encoded = this.$route.params.data
        if (encoded) {
          const decoded = decodeURIComponent(encoded)
          const decompressed = lzutf8.decompress(decoded, { inputEncoding: 'Base64' })
          text = decompressed
          this.printHash = objectHash(text)
        }
      } catch (e) {
        console.error(e)
        text = 'データが解析できませんでした。'
      }
      if (!text) text = '読み込むデータがありませんでした。'
      this.$store.commit('do/setText', text)
    },
    async checkPrintHash() {
      if (this.printHash) {
        if (await this.$store.dispatch('schedule/existPrintHash', this.printHash)) {
          this.showOverlap = true
        }
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
