<template>
  <div class="pages">
    <Page v-for="(page, index) in pages" :key="index" :ref="collect"
      :indexes="{ page: index }" :page="page"
    />
  </div>
</template>

<script>
import NumberStocker from '@/js/tools/structure/number-stocker.js'
import PagesCreator from '@/js/document/page/pages-creator.js'
import SpaceChecker from '@/js/document/page/space-checker.js'
import Page from '@/components/documents/document/pages/page/Page.vue'
export default {
  components: {
    Page
  },
  data() {
    return {
      working: false,
      destroyed: false,
      children: []
    }
  },
  computed: {
    phase() { return this.$store.getters.phase },
    structure() { return this.$store.getters[`${this.phase}/structure`] },
    pages() { return this.$store.getters['display/pages'] },
    capacities() { return this.$store.getters['display/capacities'] },
    stock() { return this.$store.getters['display/stock'] },
    status() { return this.$store.getters['display/status'] }
  },
  watch: {
    structure(structure) {
      const { pages, stock } = PagesCreator.createPagesAndStock(structure, this.capacities)
      this.$store.commit('display/setPages', pages)
      this.$store.commit('display/setStock', stock)
      this.removeNotUsedCapacity()
      // 現在のキャパシティー内に変更がないとトリガーされないので明示的にする
      this.$store.commit('display/setStatus', { index: pages.length - 1, status: 'check' })
    },
    capacities: {
      deep: true,
      handler(capacities) {
        PagesCreator.fitPagesToCapacities(this.pages, this.stock, capacities)
        this.removeNotUsedCapacity()
      }
    },
  },
  methods: {
    collect(child) {
      if (child) this.children.push(child)
    },
    removeNotUsedCapacity() {
      if (!this.stock.length) {
        for (let i = this.capacities.length - 1; i > 0; i--) {
          if (this.pages[i].length) break
          else NumberStocker.remove(this.capacities, i)
        }
      }
    },
    cycle() {
      for (let i = 0; i < this.status.length; i++) {
        if (!(i in this.status) || this.status[i] == null) continue
        const methodName = this.status[i]
        if (!this[methodName]) throw new SyntaxError('Unknown method.')
        this[methodName](i)
        this.$store.commit('display/setStatus', { index: i, status: null })
        break
      }
    },
    check(index) {
      const page = this.children[index], nextPage = this.children[index + 1]
      if (!page) return
      const fieldElm = page.$refs.field
      const words = page.children[0], nextWords = nextPage ? nextPage.children[0] : null
      const wordsElm = words.$el
      const wordList = words.children, nextWordList = nextWords ? nextWords.children : null
      // TODO: 匿名ユーザーで作成画面から学習画面に切り替わる瞬間にfieldElmがnullになる暫定対応
      if (fieldElm) this.checkOverflow(index, fieldElm, wordList)
      this.checkOpen(index)
      if (fieldElm) this.checkFreeSpace(index, fieldElm, wordsElm, nextWordList)
    },
    checkOverflow(index, fieldElm, wordList) {
      if (!wordList.length) return
      const overflow = SpaceChecker.checkOverflow(fieldElm, wordList, comp => comp.$el)
      if (overflow > 0) {
        NumberStocker.minus(this.capacities, index, overflow)
        if (!NumberStocker.exist(this.capacities, index + 1)) {
          NumberStocker.set(this.capacities, index + 1, 0)
        }
      }
    },
    checkOpen(index) {
      if (index === this.capacities.length - 1 && this.stock.length) {
        NumberStocker.add(this.capacities, index, 1)
      }
    },
    checkFreeSpace(index, fieldElm, holderElm, nextWordList) {
      if (nextWordList) {
        const free = SpaceChecker.checkFreeSpace(fieldElm, holderElm, nextWordList, comp => comp.$el)
        if (free > 0) NumberStocker.add(this.capacities, index, free)
      }
    }
  },
  mounted() {
    const loop = () => {
      if (!this.working) this.cycle()
      if (!this.destroyed) requestAnimationFrame(loop)
    }
    loop()
  },
  beforeUpdate() {
    this.working = true
    this.children.length = 0
  },
  updated() {
    this.working = false
  },
  beforeUnmount() {
    this.destroyed = true
  }
}
</script>

<style lang="css" scoped>
  .pages {
    display: flex;
    flex-direction: column;
  }
</style>
