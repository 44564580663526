export default class NumberStocker {
  static set(array, idx, num) {
    if (idx < 0) throw new RangeError('Invalid array index.')
    if (idx > 0 && !((idx - 1) in array)) throw new RangeError('Too far index.')
    array[idx] = num
  }
  static remove(array, idx) {
    if (idx < 0 || idx > array.length - 1) throw new RangeError('Invalid array index.')
    if (idx < array.length - 1) throw new RangeError('Too inside index.')
    if (idx >= 0 && idx < array.length) array.length = idx
  }
  static add(array, idx, num) {
    if (!(idx in array)) throw new RangeError('Invalid array index.')
    if (num < 1) throw new TypeError('Param "num" must be +.')
    array[idx] += num
  }
  static minus(array, idx, num) {
    if (!(idx in array)) throw new RangeError('Invalid array index.')
    if (num < 1) throw new TypeError('Param "num" must be +.')
    if (array[idx] - num < 0) throw new RangeError('Value became -.')
    array[idx] -= num
  }
  static exist(array, idx) {
    return !!(idx in array)
  }
}
