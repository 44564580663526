import LearningNoteStruct from '@/js/learning/learning-note-struct.js'
import LearningNoteTime from '@/js/learning/learning-note-time.js'

let S = null
let T = null
export default class LearningNote {
  static createStruct() {
    return S.createStruct()
  }
  static setShow(struct, value) {
    S.setShow(struct, value)
  }
  static setResult(struct, nowDate, originHour, minHoursAsDay, extendRate, result) {
    const oneShot = result && !S.getHaveIncorrect(struct)
    const now = T.calcBaseOriginTime(nowDate, originHour, minHoursAsDay)
    const last = S.getLastOneShotCorrectDateTime(struct)
    const next = S.getNextLevelDateTime(struct)
    S.setResult(struct, result)
    if (!result) {
      S.setHaveIncorrect(struct, true)
      S.setLastOneShotCorrectDateTime(struct, null)
      S.setNextLevelDateTime(struct, T.calcPlusMinusDays(now, 1))
    } else if (!oneShot) {
      S.setNextLevelDateTime(struct, T.calcPlusMinusDays(now, 1))
    } else if (!last || !next) {
      S.setNextLevelDateTime(struct, T.calcPlusMinusDays(now, 1))
      S.setLastOneShotCorrectDateTime(struct, now)
    } else if (now < next) {
      S.setNextLevelDateTime(struct, now + (next - last))
      S.setLastOneShotCorrectDateTime(struct, now)
    } else {
      S.setNextLevelDateTime(struct, now + (now - last) * extendRate)
      S.setLastOneShotCorrectDateTime(struct, now)
    }
  }
  static getResult(struct) {
    return S.getResult(struct)
  }
  static getHaveIncorrect(struct) {
    return S.getHaveIncorrect(struct)
  }
  static getNextLevelDateTime(struct) {
    return S.getNextLevelDateTime(struct)
  }
  static finished(struct) {
    const show = S.getShow(struct)
    const result = S.getResult(struct)
    return !show || result === true
  }
  static resetResult(struct) {
    S.setResult(struct, null)
  }
  static resetHaveIncorrect(struct) {
    S.setHaveIncorrect(struct, false)
  }
  static resetCurrent(struct) {
    S.setShow(struct, false)
    S.setResult(struct, null)
  }
  static resetThisTime(struct) {
    S.setHaveIncorrect(struct, false)
  }
  static resetNextTime(struct) {
    S.setNextLevelDateTime(struct, null)
  }
  static setCorrect(struct) {
    S.setResult(struct, true)
  }
}
S = LearningNoteStruct
T = LearningNoteTime
