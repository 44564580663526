import objectHash from 'object-hash'
import LearningBook from '@/js/learning/learning-book.js'
import { userFolder } from './user-store.js'

export default {
  namespaced: true,
  state: {
    refresher: {
      schedules: 0
    }
  },
  getters: {
    user(state, getters, rootState, rootGetters) {
      return rootGetters['user/user']
    },
    scheduleFolder(state, getters) {
      return userFolder.get(getters.user, 'schedule')
    },
    printHashMapFolder(state, getters) {
      return userFolder.get(getters.user, 'print-hash-map')
    },
    printHashMap(state, getters) {
      const key = 0
      const folder = getters.printHashMapFolder
      return folder.existItem(key) ? folder.getItem(key) : {}
    },
    schedules(state, getters) {
      state.refresher.schedules
      if (!getters.user) return []
      const schedules = getters.scheduleFolder
      const ret = []
      for (const dateTime of schedules.getList()) {
        const schedule = schedules.getItem(dateTime)
        ret.push({
          dateTime,
          text: schedule.text,
          learningBook: schedule.learningBook
        })
      }
      return ret
    },
    getSchedule(state, getters) {
      return dateTime => {
        if (!getters.user) return null
        const schedules = getters.scheduleFolder
        if (!schedules.existItem(dateTime)) return null
        const schedule = schedules.getItem(dateTime)
        LearningBook.setCorrectAsFuture(schedule.learningBook)
        return schedule
      }
    },
  },
  mutations: {},
  actions: {
    setPrintHashMap({ getters }, map) {
      const key = 0
      const folder = getters.printHashMapFolder
      if (!folder.existItem(key)) {
        folder.addItem(map, key)
      } else {
        folder.updateItem(map, key)
      }
    },
    existPrintHash({ getters }, hash) {
      const map = getters.printHashMap
      return map[hash]
    },
    async addPrintHash({ getters, dispatch }, hash) {
      const map = getters.printHashMap
      map[hash] = true
      await dispatch('setPrintHashMap', map)
    },
    async removePrintHash({ getters, dispatch }, hash) {
      const map = getters.printHashMap
      delete map[hash]
      await dispatch('setPrintHashMap', map)
    },
    async addSchedule({ getters, dispatch }, { text, learningBook, dateTime, beforeDateTime }) {
      const schedules = getters.scheduleFolder
      await dispatch('addPrintHash', objectHash(text))
      if (beforeDateTime) {
        schedules.updateItem({ text, learningBook }, beforeDateTime, true, dateTime)
      } else {
        schedules.addItem({ text, learningBook }, dateTime)
      }
    },
    async removeSchedule({ state, getters, dispatch }, dateTime) {
      const schedules = getters.scheduleFolder
      const schedule = schedules.getItem(dateTime)
      await dispatch('removePrintHash', objectHash(schedule.text))
      schedules.removeItem(dateTime)
      state.refresher.schedules++
    }
  }
}
