import Defs from '@/js/defs/defs.js'
import kanji from '@/js/store/kanji.js'

export default {
  policy: {
    version: 3
  },
  app: {
    input: {
      keyword: {
        escapeChars: '＃',
        bracketChars: '（）＜＞',
        newLineChars: '／',
        separatorChars: "：｜",
        // innerSeparatorChars: '・',
      }
    }
  },
  learning: {
    policy: {},
    map: {
      purposes: {
        [Defs.struct.word.purposes.write]: {
          ...kanji.school.elementary[1],
          ...kanji.school.elementary[2],
          ...kanji.school.elementary[3],
          ...kanji.school.elementary[4],
        },
        [Defs.struct.word.purposes.read]: {
          ...kanji.school.elementary[1],
          ...kanji.school.elementary[2],
          ...kanji.school.elementary[3],
          ...kanji.school.elementary[4],
          ...kanji.school.elementary[5],
          ...kanji.school.elementary[6],
        },
      }
    }
  },
  chars: {
    size: {
      // [Defs.struct.word.purposes.display]: {
      //   [Defs.struct.pair.positions.normal]: 20,
      //   [Defs.struct.pair.positions.target]: 20,
      //   [Defs.struct.pair.positions.support]: 10
      // },
      // [Defs.struct.word.purposes.mean]: {
      //   [Defs.struct.pair.positions.normal]: 20,
      //   [Defs.struct.pair.positions.target]: 20,
      //   [Defs.struct.pair.positions.support]: 10
      // },
      // [Defs.struct.word.purposes.read]: {
      //   [Defs.struct.pair.positions.normal]: 20,
      //   [Defs.struct.pair.positions.target]: 20,
      //   [Defs.struct.pair.positions.support]: 15
      // },
      // [Defs.struct.word.purposes.write]: {
      //   [Defs.struct.pair.positions.normal]: 20,
      //   [Defs.struct.pair.positions.target]: 30,
      //   [Defs.struct.pair.positions.support]: 10
      // }
      [Defs.struct.word.purposes.display]: {
        [Defs.struct.pair.positions.normal]: 10,
        [Defs.struct.pair.positions.target]: 10,
        [Defs.struct.pair.positions.support]: 5
      },
      [Defs.struct.word.purposes.mean]: {
        [Defs.struct.pair.positions.normal]: 10,
        [Defs.struct.pair.positions.target]: 10,
        [Defs.struct.pair.positions.support]: 5
      },
      [Defs.struct.word.purposes.read]: {
        [Defs.struct.pair.positions.normal]: 10,
        [Defs.struct.pair.positions.target]: 10,
        [Defs.struct.pair.positions.support]: 8
      },
      [Defs.struct.word.purposes.write]: {
        [Defs.struct.pair.positions.normal]: 10,
        [Defs.struct.pair.positions.target]: 15,
        [Defs.struct.pair.positions.support]: 5
      }
    }
  }
}
