export default class SpaceChecker {
  static newLineElm = (() => {
    const elm = document.createElement('div')
    elm.style.height = '100%'
    return elm
  })()
  static _checkOverflow(fieldRect, elm, useNewLineElm = false) {
    let result
    try {
      if (useNewLineElm) elm.insertAdjacentElement('afterend', SpaceChecker.newLineElm)
      const rect = elm.getBoundingClientRect()
      result = rect.left < fieldRect.left
    } finally {
      if (useNewLineElm) SpaceChecker.newLineElm.remove()
    }
    return result
  }
  static checkOverflow(fieldElm, elms, accessor = null) {
    if (!accessor) accessor = e => e
    const fieldRect = fieldElm.getBoundingClientRect()
    const lastElm = elms[elms.length - 1]
    if (SpaceChecker._checkOverflow(fieldRect, accessor(lastElm))) {
      let len = elms.length, capacity = 0, i, j
      for (i = 0; i < len; i++) {
        if (SpaceChecker._checkOverflow(fieldRect, accessor(elms[i]))) break
      }
      for (j = i; j < len; j++) {
        if (SpaceChecker._checkOverflow(fieldRect, accessor(elms[j]), true)) {
          capacity = j; break
        }
      }
      return len - capacity
    } else return 0
  }
  static checkFreeSpace(fieldElm, holderElm, nextElms, accessor = null) {
    if (!accessor) accessor = e => e
    const fieldRect = fieldElm.getBoundingClientRect()
    const testElms = []; let capacity = 0
    try {
      for (let i = 0; i < nextElms.length; i++){
        const testElm = accessor(nextElms[i]).cloneNode(true)
        testElms.push(testElm)
        holderElm.insertAdjacentElement('beforeend', testElm)
        if (SpaceChecker._checkOverflow(fieldRect, testElm)) break
        else capacity++
      }
    } finally {
      testElms.forEach(testElm => testElm.remove())
    }
    return capacity
  }
}
