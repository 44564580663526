// import Updater from '@/js/tools/structure/updater.js'

export default {
  namespaced: true,
  state: {
    text: null,
    structure: null,
  },
  getters: {
    text: (state) => state.text,
    structure: (state) => state.structure,
  },
  mutations: {
    clear(state) {
      state.text = null
      state.structure = null
    },
    setText(state, text) {
      state.text = text
    },
    setStructure(state, structure) {
      state.structure = structure
    },
  }
}
