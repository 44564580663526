<template>
  <div class="checker" ref="wrapper">
    <w-icon v-if="isShowingIcon">mdi mdi-gesture-tap</w-icon>
    <w-spinner v-if="isChecking" fade color="warning-light1"/>
  </div>
</template>

<script>
import { getAuth } from "firebase/auth";
import Back from '@/import/back.js'
export default {
  props: {
    char: String,
  },
  data() {
    return {
      enable: true,
      strokes: [],
      strokeNum: 0,
      isChecking: false
    }
  },
  computed: {
    user() { return this.$store.getters['user/user'] },
    isShowingIcon() {
      return this.enable && !this.strokes.length
    }
  },
  methods: {
    reset() {
      this.detector.reset()
      this.handWriter.reset()
      this.enable = true
    },
    pause() {
      this.handWriter.pause()
      this.enable = false
    },
    start() {
      this.handWriter.start()
      this.enable = true
    },
    isEmpty() {
      return !this.handWriter.strokes.length
    },
    async detect() {
      if (!this.handWriter.strokes.length) return { empty: true }
      this.pause()
      this.isChecking = true
      try {
        this.detector.addRequest({
          structure: {
            strokes: this.handWriter.strokes
          },
          image: {
            type: { mime: 'image/png' },
            size: {
              width: this.handWriter.canvasElm.width,
              height: this.handWriter.canvasElm.height
            },
            data: {
              base64: this.handWriter.getBase64()
            }
          },
          filter: { chars: { white: null, black: null } }
        })
      } catch(error) { return this.errorOfDetect(error) }
      let currentUser, idToken, response
      try {
        currentUser = getAuth().currentUser
        // if (!this.xyz) { this.xyz = true; throw new Error('test') }
      } catch(error) { return this.errorOfDetect(error) }
      if (!currentUser) return this.errorOfDetect('Could not get current user.')
      try {
        idToken = await currentUser.getIdToken().catch(error => console.error(error))
      } catch(error) { return this.errorOfDetect(error) }
      if (!idToken) return this.errorOfDetect('Could not get id token.')
      try {
        response = await this.detector.detect({ uid: this.user.uid, idToken })
      } catch(error) { return this.errorOfDetect(error) }
      if (!response) return this.errorOfDetect('Could not get response.')
      this.isChecking = false
      if (response.error) return this.errorOfDetect(response.error)
      else if (response.results.restriction) {
        return response.results
      } else {
        return { chars: response.results[0] }
      }
    },
    errorOfDetect(error) {
      this.isChecking = false
      return { error }
    }
  },
  mounted() {
    this.handWriter = new Back.HandWriter(this.$waveui)
    this.handWriter.begin()
    this.handWriter._strokes = this.strokes // 監視のためのひもづけ
    this.canvasElm = this.handWriter.canvasElm
    this.$refs.wrapper.appendChild(this.canvasElm)
    this.canvasElm.style.position = 'absolute'
    this.canvasElm.style.width = '100%'
    this.canvasElm.style.height = '100%'
    this.canvasElm.style.zIndex = 2000

    this.detector = new Back.DetectorClient()
  }
}
</script>

<style lang="scss" scoped>
div.checker {
  height: 100%;
  i.w-icon {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }
  div.w-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
