import Defs from '@/js/defs/defs.js'
import setting from '@/js/store/setting.js'
import SearchStrFromMaps from '@/js/tools/string/search-str-from-maps.js'
import RegExpTools from '@/js/tools/string/reg-exp-tools.js'

export class Document {
  constructor(getters) {
    this.getters = getters
  }
  isPlanPhase() {
    return this.getters.phase === 'plan'
  }
  isDoPhase() {
    return this.getters.phase === 'do'
  }
}
export class Learning {
  constructor(getters) {
    this.getters = getters
  }
  isFirstTryPhase() {
    return this.getters['do/learningType'] === Defs.get('learning.types.firstTry')
  }
  getPurpose(chars, type) {

    // console.log(this.isFirstTryPhase())


    const maps = setting.learning.map.purposes
    const p = Defs.struct.word.purposes
    if (type && type.match('書')) return p.write;
    else if (type && type.match('読')) return p.read;
    else if (type && type.match('表')) return p.display;
    else if (type && type.match('説')) return p.mean;
    else {
      const purposes = [p.write, p.read]
      const existences = SearchStrFromMaps.search(
        RegExpTools.removeHiragana(chars), maps[purposes[0]], maps[purposes[1]]
      )
      for (let i = 0; i < purposes.length; i++) {
        const allExist = existences.reduce((total, current) => total & current[i], true)
        if (allExist) return purposes[i]
      }
      return Defs.struct.word.purposes.display
    }
  }
}

export default class Director {
  constructor(getters) {
    this.getters = getters
    this.document = new Document(getters)
    this.learning = new Learning(getters)
  }
}
