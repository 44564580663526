<template>
  <w-flex class="frame fill-height align-center justify-center">
    <a href="https://doc.mihiraku.com/services/print" target="_blank" rel="noopener noreferrer">ドキュメント</a>
    <a href="https://doc.mihiraku.com/services/print/policies/user" target="_blank" rel="noopener noreferrer">利用規約</a>
    <a href="https://doc.mihiraku.com/services/print/price" target="_blank" rel="noopener noreferrer">価格表</a>
    <a href="https://doc.mihiraku.com/services/print/payment" target="_blank" rel="noopener noreferrer">お支払い方法</a>
    <a href="https://doc.mihiraku.com/services/print/policies/privacy" target="_blank" rel="noopener noreferrer">個人情報の取り扱い</a>
    <a href="https://doc.mihiraku.com/services/print/policies/trading" target="_blank" rel="noopener noreferrer">特定商取引法に基づく表記</a>
  </w-flex>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
div.frame {
  font-size: small;
  background-color: #cad3d8;
  a {
    margin: auto 0.25rem;
    line-height: 125%;
    color: #526670;
  }
}
</style>
