import panzoom from 'panzoom'

export default class Zoomer {
  static _getGlobalRect(elm) {
    let ret = null
    const get = ({ width, height, left, top }) => {
      ret = { width, height, left, top }
    }
    get(elm.getBoundingClientRect())
    return ret
  }
  static _calcLocalRect(baseElm, targetElm) {
    const baseRect = Zoomer._getGlobalRect(baseElm)
    const targetRect = Zoomer._getGlobalRect(targetElm)
    return {
      left: targetRect.left - baseRect.left,
      top: targetRect.top - baseRect.top,
      width: targetRect.width,
      height: targetRect.height,
    }
  }
  static _scaleRect(targetRect, scale) {
    return {
      left: targetRect.left * scale,
      top: targetRect.top * scale,
      width: targetRect.width * scale,
      height: targetRect.height * scale,
    }
  }
  // ターゲットがフレームにフィットする縦または横のスケールを返す
  static _calcFittingScale(frameRect, targetRect) {
    const widthScale = frameRect.width / targetRect.width
    const heightScale = frameRect.height / targetRect.height
    return Math.min(widthScale, heightScale)
  }
  // ターゲットがフレームの中央にくるフィールド座標を返す
  static _calcFittingPos(frameSize, targetRect) {
    return {
      left: -targetRect.left + (frameSize.width - targetRect.width) / 2,
      top: -targetRect.top + (frameSize.height - targetRect.height) / 2
    }
  }
  static _calcRatedValue(from, to, rate) {
    return from + Math.abs(to - from) * rate * (from <= to ? 1 : -1)
  }
  constructor(scaleElm) {
    this.fieldElm = scaleElm
    this.frameElm = (this.fieldElm || {}).parentNode
    this.scale = 1
    if (!(this.fieldElm instanceof Element)
        || !(this.frameElm instanceof Element)) {
      throw new TypeError('Invalid type.')
    }
    this.zoomer = panzoom(this.fieldElm, {
      // transformOrigin: { x: 0, y: 0 },  // ホイールズーム／ピンチズーム時の起点
      zoomDoubleClickSpeed: 1,          // ダブルクリックズーム無効
      smoothScroll: false,
      bounds: true,
    })
  }
  _zoom(rate, {
    pos: { xFrom, xTo, yFrom, yTo },
    scale: { scaleFrom, scaleTo },
  }) {
    const x = Zoomer._calcRatedValue(xFrom, xTo, rate)
    const y = Zoomer._calcRatedValue(yFrom, yTo, rate)
    const scale = Zoomer._calcRatedValue(scaleFrom, scaleTo, rate)
    const current = this.zoomer.getTransform()
    const additionalScale = scale / current.scale
    this.zoomer.zoomTo(current.x, current.y, additionalScale)
    this.zoomer.moveTo(x, y)
  }
  _animation({
    pos: { xFrom, xTo, yFrom, yTo },
    scale: { scaleFrom, scaleTo },
    time: { timeFrom, timeTo }
  }) {
    const now = Date.now()
    let rate = null
    if (now <= timeFrom) rate = 0
    else if (now >= timeTo) rate = 1
    else rate = (now - timeFrom) / (timeTo - timeFrom)
    this._zoom(rate, {
      pos: { xFrom, xTo, yFrom, yTo },
      scale: { scaleFrom, scaleTo }
    })
    return rate !== 1
  }
  _smoothZoom(x, y, additionalScale, ms) {
    const current = this.zoomer.getTransform()
    const options = {
      pos: {
        xFrom: current.x,
        xTo: x,
        yFrom: current.y,
        yTo: y
      },
      scale: {
        scaleFrom: current.scale,
        scaleTo: current.scale * additionalScale
      },
      time: {
        timeFrom: Date.now(),
        timeTo: Date.now() + ms
      }
    }
    const caller = () => {
      requestAnimationFrame(() => {
        if (this._animation(options)) caller()
      })
    }
    caller()
  }
  // fit(targetElm) {
  fit(targetRect) {
    const globalFrameRect = Zoomer._getGlobalRect(this.frameElm)
    // const targetRect = Zoomer._calcLocalRect(this.fieldElm, targetElm)
    const additionalScale = Zoomer._calcFittingScale(globalFrameRect, targetRect)
    const scaledTargetRect = Zoomer._scaleRect(targetRect, additionalScale)
    const pos = Zoomer._calcFittingPos(globalFrameRect, scaledTargetRect)
    this._smoothZoom(pos.left, pos.top, additionalScale, 200)
  }
  pause() {
    this.zoomer.pause()
  }
  resume() {
    this.zoomer.resume()
  }
}
