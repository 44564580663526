import ArraysController from '@/js/tools/structure/arrays-controller.js'

export default class PagesCreator {
  static createPagesAndStock(wordsLiner, capacities) {
    const pages = []; let i = 0
    capacities.forEach(capacity => {
      const page = wordsLiner.slice(i, i + capacity)
      pages.push(page)
      i += capacity
    })
    const stock = wordsLiner.slice(i)
    return { pages, stock }
  }
  static fitPagesToCapacities(pages, stock, capacities) {
    let i
    for (i = 0; i < capacities.length; i++) {
      const capacity = capacities[i]
      const page = !(i in pages) ? pages[i] = [] : pages[i]
      const len = page.length
      if (len < capacity) {
        ArraysController.collectFromSideAll([...pages, stock], i, capacity - len)
      } else if (len > capacity) {
        ArraysController.giveToSide([...pages, stock], i, len - capacity)
      }
    }
    pages.slice(i).forEach(restPage => stock.unshift(...restPage))
    pages.length = i
  }
}
