export class Rect {
  static getRect(elm) {
    let ret = null
    const get = ({ width, height, left, top }) => {
      ret = { width, height, left, top }
    }
    get(elm.getBoundingClientRect())
    return ret
  }
  static getCenterPos(elm) {
    const rect = Rect.getRect(elm)
    return {
      x: rect.left + rect.width / 2,
      y: rect.top + rect.height / 2
    }
  }
  static toLocalRect(fieldElm, rect) {
    const fieldRect = Rect.getRect(fieldElm)
    return {
      left: rect.left - fieldRect.left,
      top: rect.top - fieldRect.top,
      width: rect.width,
      height: rect.height
    }
  }
}

export default class Selector {
  static _watchEvents = {
    start: ['mousedown', 'touchstart'],
    move: ['mousemove', 'touchmove'],
    end: ['mouseup', 'touchend'],
  }
  static _searchUpperElms(innerElm, topElm, func) {
    const call = e => {
      const stop = func(e)
      const parentElm = e.parentElement
      if (!stop && e !== topElm && parentElm) call(parentElm)
    }
    call(innerElm)
  }
  _watchEvents() {
    const struct = Selector._watchEvents
    const elm = this._fieldElm
    for (const kind in struct) {
      for (const name of struct[kind]) {
        elm.addEventListener(name, this._on.bind(this, kind, name))
      }
    }
  }
  _clearOperation() {
    this._operation.start = null
    this._operation.buttonId = null
    this._operation.touchCount = null
    this._operation.move = null
  }
  _on(kind, name, event) {
    if (this._pause) return
    const callFuncName = `_on${kind[0].toLocaleUpperCase()}${kind.substr(1)}`
    this[callFuncName](event)
  }
  _onStart(event) {
    // 複数指のタップはまとまって一度だけ呼ばれる場合もあるし、
    // 連続して複数呼ばれる場合もあるので、タッチ数を上書きする
    const o = this._operation
    o.start = true
    if (event instanceof MouseEvent) {
      o.buttonId = event.button
    } else if (event instanceof TouchEvent) {
      o.touchCount = event.touches.length
    }
  }
  _onMove() {
    const o = this._operation
    if (!o.start) return
    o.move = true
  }
  _onEnd(event) {
    let x, y
    const o = this._operation
    if (o.start) {
      const center = Rect.getCenterPos(this._frameElm)
      if (event instanceof MouseEvent) {
        x = event.clientX
        y = event.clientY
        if (!o.move) {
          if (o.buttonId === 0) {
            this._changeSelect(x, y, true)
          } else if (o.buttonId === 2) {
            this._changeSelect(center.x, center.y, false)
          }
        }
      } else if (event instanceof TouchEvent) {
        // touchendイベントはタッチ情報が消えているのでchangedTouchesを参照する
        x = event.changedTouches.item(0).clientX
        y = event.changedTouches.item(0).clientY
        if (!o.move && o.touchCount === 1) {
          this._changeSelect(x, y, true)
          // 三本指タップはドラッグしていても処理する
        } else if (o.touchCount === 3) {
          this._changeSelect(center.x, center.y, false)
        }
      }
    }
    this._clearOperation()
  }
  _getMatchedRects(elm) {
    // Element.classListの順番でマッチしたRectを返す
    const ret = []
    for (let i = 0; i < elm.classList.length; i++) {
      const className = elm.classList[i]
      if (className in this._targetOptions) {
        const option = this._targetOptions[className]
        if (!option.check || option.check(elm)) {
          const rect = (option.rect || Rect.getRect)(elm)
          ret.push(rect)
        }
      }
    }
    return ret
  }
  _analyzeMatchedElms(elms) {
    const frameRect = Rect.getRect(this._frameElm)
    const ret = { inside: [], outside: [] }
    let beforeRect = {}
    elms.forEach(elm => {
      if (this._fieldElm.contains(elm)) {
        for (const rect of this._getMatchedRects(elm)) {
          // 小さいCharsとPairなど、直前と同一の領域を除外する
          if (rect.left !== beforeRect.left || rect.top !== beforeRect.top || rect.width !== beforeRect.width || rect.height !== beforeRect.height) {
            // （同一領域を除外した後に）フォーカス中の要素なら除外する
            if (elm !== this._select.elm){
              const diffWidth = frameRect.width - rect.width
              const diffHeight = frameRect.height - rect.height
              const diff = Math.min(diffWidth, diffHeight)
              const size = rect.width * rect.height
              ret[diff >= 0 ? 'inside' : 'outside'].push({ elm, rect, diff, size })
            }
          }
          beforeRect = rect
        }
      }
    })
    ret.inside.sort((i1, i2) => i2.size - i1.size)  // サイズが大きい順に並べる
    ret.outside.sort((i1, i2) => i1.size - i2.size) // サイズが小さい順に並べる
    return ret
  }
  _changeSelect(x, y, insideOrOutside) {
    const elms = document.elementsFromPoint(x, y)
    const result = this._analyzeMatchedElms(elms)
    let targetItem = null
    if (insideOrOutside) targetItem = result.inside[0] || result.outside[0]
    else targetItem = result.outside[0] || result.inside[0]
    if (targetItem) this._setSelect(targetItem.elm, targetItem.rect)
  }
  _setSelect(elm, rect, noCallback = false) {
    this._select.elm = elm
    this._select.rect = rect ? Rect.toLocalRect(this._fieldElm, rect) : null
    if (!noCallback) this._callback(this._select)
  }
  constructor(frameElm, fieldElm, targetOptions, callback) {
    this._frameElm = frameElm
    this._fieldElm = fieldElm
    this._targetOptions = targetOptions
    this._callback = callback
    this._operation = {
      start: null,
      buttonId: null,
      touchCount: null,
      move: null
    }
    this._select = {
      elm: null,
      rect: null
    }
    this._pause = false
    this._watchEvents()
  }
  zoomOutToField() {
    this._setSelect(this._fieldElm, Rect.getRect(this._fieldElm))
  }
  pause() {
    this._pause = true
  }
  resume() {
    this._pause = false
  }
}
