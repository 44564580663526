// https://eslint.org/docs/rules/no-control-regex
/*eslint no-control-regex: off*/

// NOTE: スマートフォンのブラウザは、正規表現の後読み(?<..)に対応していないので、使用しないこと
export default class StrWidthConverter {
  static unknownChar = '？'
  static signsPairs = [
    ["\u0020", "\u3000"], // 半角スペース⇔全角スペース
    ["\u0021", "\uFF01"], // !⇔！
    ["\u0022", "\u201D"], // "⇔”
    ["\u0023", "\uFF03"], // #⇔＃
    ["\u0024", "\uFF04"], // $⇔＄
    ["\u0025", "\uFF05"], // %⇔％
    ["\u0026", "\uFF06"], // &⇔＆
    ["\u0027", "\u2019"], // '⇔’
    ["\u0028", "\uFF08"], // (⇔（
    ["\u0029", "\uFF09"], // )⇔）
    ["\u002A", "\uFF0A"], // *⇔＊
    ["\u002B", "\uFF0B"], // +⇔＋
    ["\u002C", "\uFF0C"], // ,⇔，
    ["\u002D", "\uFF0D"], // -⇔－
    ["\u002E", "\uFF0E"], // .⇔．
    ["\u002F", "\uFF0F"], // /⇔／
    ["\u003A", "\uFF1A"], // :⇔：
    ["\u003B", "\uFF1B"], // ;⇔；
    ["\u003C", "\uFF1C"], // <⇔＜
    ["\u003D", "\uFF1D"], // =⇔＝
    ["\u003E", "\uFF1E"], // >⇔＞
    ["\u003F", "\uFF1F"], // ?⇔？
    ["\u0040", "\uFF20"], // @⇔＠
    ["\u005B", "\uFF3B"], // [⇔［
    ["\u005C", "\uFFE5"], // \⇔￥
    ["\u005D", "\uFF3D"], // ]⇔］
    ["\u005E", "\uFF3E"], // ^⇔＾
    ["\u005F", "\uFF3F"], // _⇔＿
    ["\u0060", "\uFF40"], // `⇔｀
    ["\u007B", "\uFF5B"], // {⇔｛
    ["\u007C", "\uFF5C"], // |⇔｜
    ["\u007D", "\uFF5D"], // }⇔｝
    ["\u007E", "\uFF5E"], // ~⇔～
    ["\uFF61", "\u3002"], // ｡⇔。
    ["\uFF62", "\u300C"], // ｢⇔「
    ["\uFF63", "\u300D"], // ｣⇔」
    ["\uFF64", "\u3001"], // ､⇔、
    ["\uFF65", "\u30FB"] // ･⇔・
  ]
  static kanasMap = {
      'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
      'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
      'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
      'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
      'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
      'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
      'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
      'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
      'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
      'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
      'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
      'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
      'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
      'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
      'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
      'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
      'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
      'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
      '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・'
  }
  static regExpStrOfHalf = '\x01-\x7E'
  static regExpOfHalf = new RegExp(`[${StrWidthConverter.regExpStrOfHalf}]`, 'g')
  static regExpOfFull = new RegExp(`[^${StrWidthConverter.regExpStrOfHalf}]`, 'g')
  static regExpOfHalfKanas = new RegExp(`(${Object.keys(StrWidthConverter.kanasMap).join('|')})`, 'g')
  // https://www.yoheim.net/blog.php?q=20191101
  static convertHalf0ToZToFull(str) {
    return str.replace(/[A-Za-z0-9]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) + 0xFEE0)
    }).replaceAll()
  }
  // https://stabucky.com/wp/archives/7417
  static convertHalfSignsToFull(str) {
    const pairs = StrWidthConverter.signsPairs;
    for(let i = 0; i < pairs.length; i++) {
      const before = pairs[i][0];
      const after = pairs[i][1];
      while(str != str.replace(before, after)) {
        str = str.replace(before, after);
      }
    }
    return str;
  }
  // https://www.yoheim.net/blog.php?q=20191101
  static convertHalfKanaToFullKana(str) {
    return str.replace(StrWidthConverter.regExpOfHalfKanas, (match) => {
      return StrWidthConverter.kanasMap[match]
    }).replace(/ﾞ/g, '゛').replace(/ﾟ/g, '゜')
  }
  static convertHalfToFull(str, char) {
    return str.replaceAll(StrWidthConverter.regExpOfHalf, char)
  }
  static convert(str) {
    const C = StrWidthConverter
    str = C.convertHalf0ToZToFull(str)
    str = C.convertHalfSignsToFull(str)
    str = C.convertHalfKanaToFullKana(str)
    return C.convertHalfToFull(str, C.unknownChar)
  }
}
