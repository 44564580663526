import Common from '@/import/common.js'

let C = null
export default class Defs {
  static struct = {
    document: {
      phases: {
        plan: 'plan',
        do: 'do',
        check: 'check',
        action: 'action',
      }
    },
    word: {
      purposes: {
        display: 'display',
        mean: 'mean',
        read: 'read',
        write: 'write'
      }
    },
    pair: {
      positions: {
        normal: 'normal',
        target: 'target',
        support: 'support'
      },
    },
    chars: {
      types: {
        text: 'text',
        prefix: 'prefix',
        suffix: 'suffix'
      }
    }
  }
  static get(path) {
    return Common.Struct.query(C.struct, path)
  }
  static checkExistValue(path, value) {
    const struct = C.get(path)
    if (struct === undefined) throw new ReferenceError('Not found struct.')
    if (!Common.Type.isPlainObject(struct)) throw new TypeError('Invalid struct.')
    for (const key of Object.keys(struct)) {
      if (struct[key] === value) return
    }
    throw new TypeError('Invalid value.')
  }
}
C = Defs
