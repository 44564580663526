import objectHash from 'object-hash'

export default class HashMap {
  static create(object) {
    return objectHash(object)
  }
  constructor() {
    this.map = {}
  }
  push(hash, item = null) {
    if (!(hash in this.map)) this.map[hash] = []
    this.map[hash].push(item)
  }
  shift(hash) {
    if (!(hash in this.map)) throw new ReferenceError('Not found hash.')
    const array = this.map[hash]
    if (array.length <= 0) throw new RangeError('Array is empty.')
    const item = array.shift()
    if (array.length <= 0) delete this.map[hash]
    return item
  }
  num(hash) {
    return (hash in this.map) ? this.map[hash].length : 0
  }
}
