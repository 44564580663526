<template>
  <!-- .scrollable: for App.vue -->
  <div ref="editor" class="editor scrollable" contenteditable="true" :style="styles.editor"
    @keydown.enter="onEnter" @input="onInput" @paste="onPaste"
    @compositionstart="onCompositionStart" @compositionend="onCompositionEnd">
  </div>
</template>

<script>
import RegExpTools from '@/js/tools/string/reg-exp-tools.js'
import Selection from '@/js/tools/dom/selection.js'
import StrWidthConverter from '@/js/tools/string/str-width-converter.js'
import StrToStructureConverter from '@/js/tools/string/str-to-structure-converter.js'
export default {
  data() {
    return {
      state: {
        composition: {
          selecting: false,
          length: null,
          index: null,
          inputText: null,
          outputText: null,
        }
      },
      styles: {
        editor: {
          boxSizing: 'border-box',
          backgroundColor: '#fdfdfd',
          width: '100%',
          height: '100%',
          fontSize: 'large',//'16px',  // https://zapanet.info/blog/item/3359
          padding: '5px',
          lineHeight: '150%'
        }
      }
    }
  },
  computed: {
    editorElm() { return this.$refs.editor },
    phase() { return this.$store.getters.phase },
    storedText() {
      // if (this.phase === 'plan') return this.$store.getters['plan/text']
      // else if (this.phase === 'do') return this.$store.getters['do/text']
      // return null
      return this.$store.getters[`${this.phase}/text`]
    }
  },
  watch: {
    storedText() {
      this.updateStructure()
    }
  },
  mounted() {
    this.converter = new StrToStructureConverter(this.$store.getters.setting.app.input.keyword)
    this.setText(this.storedText)
    this.updateStructure()
  },
  methods: {
    setText(text) {
      this.editorElm.textContent = text
    },
    getText() {
      return this.editorElm.textContent
    },
    getInputText() {
      const c = this.state.composition
      if (!c.selecting || c.index === null) return null
      const string = this.getText()
      return string.substr(c.index, string.length - c.length)
    },
    insertBrackets(startIndex, endIndex, yomi) {
      const text = this.getText()
      const before = text.substring(0, startIndex)
      const selection = text.substring(startIndex, endIndex)
      const after = text.substring(endIndex)
      this.setText(`${before}（${selection}｜${yomi}）${after}`)
      this.sendText()
    },
    sendText() {
      const text = this.getText()
      this.$store.commit('plan/setText', text)
    },
    updateStructure() {
      const wideString = StrWidthConverter.convert(this.storedText)
      const { structure } = this.converter.convert(wideString)
      // if (this.phase === 'plan') this.$store.commit('plan/setStructure', structure)
      // else if (this.phase === 'do') this.$store.commit('do/setStructure', structure)
      this.$store.commit(`${this.phase}/setStructure`, structure)
    },
    onCompositionStart() {
      const c = this.state.composition
      c.selecting = true
      c.length = null
      c.index = null
      c.inputText = null
      c.outputText = null
      const selection = Selection.getSelectedStruct(this.editorElm)
      if (selection && !this.converter.isIncludingBracket(selection.start, selection.start)) {
        c.index = selection.start
        c.length = selection.before.length + selection.after.length
      }
    },
    onCompositionEnd() {
      const c = this.state.composition
      c.outputText = this.getInputText()
      if (c.inputText && c.outputText) {
        if (RegExpTools.includeKanji(c.outputText)
            && RegExpTools.onlyHiraganaOrKanjiOrKutouten(c.outputText)) {
          this.insertBrackets(c.index, c.index + c.outputText.length, c.inputText)
          const selectIndex = c.index + c.outputText.length + c.inputText.length + 3
          Selection.setSelection(this.editorElm, selectIndex, selectIndex)
        }
      }
      c.selecting = false
      this.sendText()
    },
    onInput() {
      const c = this.state.composition
      if (c.selecting) {
        const text = this.getInputText()
        if (text && RegExpTools.onlyHiraganaOrLongSoundOrKutouten(text)) c.inputText = text
      } else this.sendText()
    },
    onEnter(event) {
      const selection = Selection.getSelectedStruct(this.editorElm)
      if (selection) {
        event.preventDefault()
        if ((selection.end - selection.start) > 0) {
          if (!this.converter.isIncludingBracket(selection.start, selection.end)) {
            this.insertBrackets(selection.start, selection.end, '？')
            const selectIndex = selection.start + selection.selection.length + 2
            Selection.setSelection(this.editorElm, selectIndex, selectIndex + 1)
          }
        } else {
          // NOTE: iphoneのchromeブラウザで完了を押してもフォーカスが外れなかったので、エンターによってフォーカスを外すようにした
          this.editorElm.blur()
        }
      }
    },
    onPaste(event) {
      event.preventDefault();
      const selection = Selection.getSelectedStruct(this.editorElm)
      if (selection) {
        const pasted = event.clipboardData.getData("text/plain");
        this.setText(`${selection.before}${pasted}${selection.after}`)
        this.sendText()
        const index = selection.start + pasted.length
        Selection.setSelection(this.editorElm, index, index)
      }
    }
  },
}
</script>

<style lang="css" scoped>
  .editor:focus {
    outline: none;
  }
</style>
