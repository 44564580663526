import hash from 'object-hash'
import setting from '@/js/store/setting.js'
import Common from '@/import/common.js'
import UserFolder from './user-folder.js'

export const userFolder = new UserFolder(
  'print.mihiraku.com',
  Common.DateTime.ControllableDate
)

export default {
  namespaced: true,
  state: {
    agreeVersion: 1,
    checkedUser: null,
    user: null,
    _tmpAgreedNotifier: 0,
    _agreedNotifier: 0,
    requestDialog: null
  },
  getters: {
    checkedUser: state => state.checkedUser,
    user: state => state.user,
    tmpAgreed(state, getters) {
      state._tmpAgreedNotifier
      const version = setting.policy.version
      const folder = getters.tmpAgreedFolder
      return folder.existItem(version) ? folder.getItem(version) : false
    },
    agreed(state, getters) {
      state._agreedNotifier
      if (!state.user) return false
      const version = setting.policy.version
      const folder = getters.agreedFolder
      return folder.existItem(version) ? folder.getItem(version) : false
    },
    requestDialog: state => state.requestDialog,
    tmpAgreedFolder() { return userFolder.get({ uidHash: 'tmp-user' }, 'tmp-agreed', true) },
    agreedFolder(state) { return userFolder.get(state.user, 'agreed') }
  },
  mutations: {
    setUser(state, user) {
      state.user = {
        uid: user.uid,
        uidHash: hash(user.uid),
        displayName: user.displayName,
        isAnonymous: user.isAnonymous
      }
      state.checkedUser = true
    },
    setNoUser(state) {
      state.checkedUser = true
      state.user = null
    },
    plusTmpAgreedNotifier(state) {
      state._tmpAgreedNotifier++
    },
    plusAgreedNotifier(state) {
      state._agreedNotifier++
    },
    setRequestDialog(state, value) {
      state.requestDialog = value
    },
    clear(state) {
      state.checkedUser = true
      state.user = null
      state.requestDialog = null
    }
  },
  actions: {
    setTmpAgreed({ getters, commit }, value) {
      commit('plusTmpAgreedNotifier')
      const version = setting.policy.version
      const folder = getters.tmpAgreedFolder
      if (folder.existItem(version)) {
        folder.updateItem(value, version)
      } else {
        folder.addItem(value, version)
      }
    },
    clearTmpAgreed({ getters, commit }) {
      commit('plusTmpAgreedNotifier')
      const version = setting.policy.version
      const folder = getters.tmpAgreedFolder
      if (folder.existItem(version)) {
        folder.removeItem(version)
      }
    },
    addAgreed({ getters, commit }, date) {
      commit('plusAgreedNotifier')
      const version = setting.policy.version
      const folder = getters.agreedFolder
      if (folder.existItem(version)) {
        folder.updateItem(date, version)
      } else {
        folder.addItem(date, version)
      }
    }
  }
}
