<template>
  <w-flex class="frame column">
    <h1>スケジュール</h1>
    <div class="prints scrollable">
      <div v-for="(print, index) in enablePrints" :key="index">
        <router-link :to="`/learn:${print.schedule.dateTime}`">
          <div class="item">
            <w-card title="今日のプリント" title-class="blue-light5--bg">
              <p>
                問題数：{{ print.summary.question.count }}
              </p>
            </w-card>
          </div>
        </router-link>
      </div>
      <div v-for="(print, index) in disablePrints" :key="index">
        <router-link :to="`/learn:${print.schedule.dateTime}`">
          <div class="item">
            <w-card title="予定のプリント" title-class="grey-light5--bg">
              約 {{ print.days }} 日後に予定されています
            </w-card>
          </div>
        </router-link>
      </div>
    </div>
  </w-flex>
</template>

<script>
import Common from '@/import/common.js'
const ControllableDate = Common.DateTime.ControllableDate
export default {
  computed: {
    schedules() { return this.$store.getters['schedule/schedules'] },
    enablePrints() {
      const ret = []
      for (const schedule of this.schedules) {
        if (schedule.dateTime <= ControllableDate.now()) {
          const print = { schedule, summary: this.getSummary(schedule) }
          ret.push(print)
        }
      }
      return ret
    },
    disablePrints() {
      const ret = []
      for (const schedule of this.schedules) {
        if (schedule.dateTime > ControllableDate.now()) {
          ret.push({ schedule, days: this._calcHowManyDaysLater(schedule) })
        }
      }
      return ret
    }
  },
  methods: {
    _calcHowManyDaysLater(schedule) {
      const diff = schedule.dateTime - ControllableDate.now()
      return Math.ceil(diff / (24 * 60 * 60 * 1000))
    },
    _searchScheduleStruct(schedule, callback) {
      const kanjiMap = schedule.learningBook
      for (const kanjiWord in kanjiMap) {
        const yomiMap = kanjiMap[kanjiWord]
        for (const yomiWord in yomiMap) {
          const questionMap = yomiMap[yomiWord]
          for (const questionType in questionMap) {
            const indexMap = questionMap[questionType]
            for (const index in indexMap) {
              callback(indexMap[index])
            }
          }
        }
      }
    },
    getSummary(schedule) {
      const ret = {
        question: { count: 0 }
      }
      this._searchScheduleStruct(schedule, (attrs) => {
        if (attrs.nextTime.nextLevelDateTime <= ControllableDate.now()) {
          ret.question.count++
        }
      })
      return ret
    }
  }
}
</script>

<style lang="scss" scoped>
.frame {
  height: 100%;
  h1 {
    text-align: center;
    margin-top: 3rem;
  }
  .prints {
    overflow-y: scroll;
    padding: 0 2rem;
    .item {
      padding: 1rem;
    }
  }
}
</style>
