<template>
  <w-dialog v-model="show" persistent :width="500" transition="twist">
    <template #title>
      <w-icon class="mr2">mdi mdi-account-check</w-icon>
      利用規約への同意
    </template>
    <p>
      サービスを利用するには、下記の利用規約と個人情報の取り扱いに同意していただく必要があります。
      <a href="https://doc.mihiraku.com/services/print" target="_blank" rel="noopener noreferrer">ドキュメント</a>
    </p>
    <p>
    <w-checkbox v-model="checkedUserPolicy">
      <span>
        <a href="https://doc.mihiraku.com/services/print/policies/user" target="_blank" rel="noopener noreferrer">
          利用規約
        </a>
        を遵守して利用することに同意します。
      </span>
    </w-checkbox>
    <w-checkbox v-model="checkedPrivacyPolicy">
      <span>
        <a href="https://doc.mihiraku.com/services/print/policies/privacy" target="_blank" rel="noopener noreferrer">
          個人情報の取り扱い
        </a>
        に同意します。
      </span>
    </w-checkbox>
    </p>
    <w-button :disabled="!(checkedUserPolicy && checkedPrivacyPolicy)" @click="onUserAgreed" bg-color="success">
      同意する
    </w-button>
  </w-dialog>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      checkedUserPolicy: false,
      checkedPrivacyPolicy: false
    }
  },
  computed: {
    checkedUser() { return this.$store.getters['user/checkedUser'] },
    user() { return this.$store.getters['user/user'] },
    tmpAgreed() { return this.$store.getters['user/tmpAgreed'] },
    agreed() { return this.$store.getters['user/agreed'] },
  },
  watch: {
    checkedUser() { this.refresh() },
    user() { this.refresh() },
    tmpAgreed() { this.refresh() },
    agreed() { this.refresh() },
  },
  beforeMount() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.show = this.checkedUser
        && (
          !this.user && !this.tmpAgreed
          || this.user && !this.agreed
        )
    },
    onUserAgreed() {
      const date = (new Date()).toString()
      if (this.user) this.$store.dispatch('user/addAgreed', date)
      else this.$store.dispatch('user/setTmpAgreed', date)
    },
  }
}
</script>

<style lang="css" scoped>
</style>
