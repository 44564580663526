<template>
  <Layout v-if="show"/>
</template>

<script>
import Defs from '@/js/defs/defs.js'
import Layout from '@/components/layout/Layout.vue'
export default {
  components: {
    Layout
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    user() { return this.$store.getters['user/user'] },
    dateTimeParam() { return this.$route.params.dateTime },
    dateTimeStr() { return this.dateTimeParam ? this.dateTimeParam.replace(/^:/, '') : null },
    dateTime() { return this.dateTimeStr ? Number(this.dateTimeStr) : null },
    getSchedule() { return this.$store.getters['schedule/getSchedule'] },
    schedule() { return this.user && this.dateTime ? this.getSchedule(this.dateTime) : null }
  },
  watch: {
    schedule() {
      if (this.schedule) this.setLearnData()
    }
  },
  beforeMount() {
    this.$store.commit('setPhase', Defs.get('document.phases.do'))
  },
  mounted() {
    if (this.schedule) this.setLearnData()
  },
  beforeUnmount() {
    this.$store.commit('display/clear')
    this.$store.commit('do/clear')
    this.$store.commit('setPhase', null)
  },
  methods: {
    setLearnData() {
      this.$store.commit('do/setText', this.schedule.text)
      this.$store.commit('do/setLearningBook', this.schedule.learningBook)
      this.show = true
    }
  }
}
</script>

<style lang="css" scoped>
</style>
