// NOTE: エスケープ文字は直前ではなく直後に置く仕様
export default class StrForRegExp {
  static _longSound = 'ー'
  static _kuten = '。'
  static _touten = '、'
  static get _hiragana() { return '{Script=Hiragana}' }
  static get hiragana() { return `\\p${StrForRegExp._hiragana}` }
  static get notHiragana() { return `\\P${StrForRegExp._hiragana}` }
  static get _katakana() { return '{Script_Extensions=Katakana}' }
  static get katakana() { return `\\p${StrForRegExp._katakana}` }
  static get notKatakana() { return `\\P${StrForRegExp._katakana}` }
  static get _kanji() { return '{Script_Extensions=Han}' }
  static get kanji() { return `\\p${StrForRegExp._kanji}` }
  static get notKanji() { return `\\P${StrForRegExp._kanji}` }
  static or(...regStrs) {
    if (regStrs.length < 2)
      throw new TypeError('Requires two or more arguments.')
    let multi = ''
    for (const regStr of regStrs) {
      if (typeof(regStr) !== 'string')
        throw new TypeError('Invalid type.')
      multi += `${multi ? '|' : ''}${regStr}`
    }
    return `(${multi})`
  }
  static any(chars) {
    if (typeof(chars) !== 'string')
      throw new TypeError('Invalid type.')
    if (chars.length < 1)
      throw new TypeError('Must be one or more chars.')
    return `[${chars}]`
  }
  static not(chars) {
    if (typeof(chars) !== 'string')
      throw new TypeError('Invalid type.')
    if (chars.length < 1)
      throw new TypeError('Must be one or more chars.')
    return `[^${chars}]`
  }
  static repeat(regStr) {
    if (typeof(regStr) !== 'string')
      throw new TypeError('Invalid type.')
    return `(${regStr})+`
  }
  static repeatOrEmpty(regStr) {
    if (typeof(regStr) !== 'string')
      throw new TypeError('Invalid type.')
    return `(${regStr})*`
  }
  static only(regStr) {
    if (typeof(regStr) !== 'string')
      throw new TypeError('Invalid type.')
    return `^${StrForRegExp.repeat(regStr)}$`
  }
  static excludeAfter(regStr, chars) {
    if (typeof(regStr) !== 'string' || typeof(chars) !== 'string')
      throw new TypeError('Invalid type.')
    if (chars.length < 1)
      throw new TypeError('Must be one or more chars.')
    return `${regStr}(?![${chars}])`
  }
  static betweenBrackets(startChar, endChar, escapeChars) {
    if (typeof(startChar) !== 'string' || typeof(endChar) !== 'string'
      || typeof(escapeChars) !== 'string') throw new TypeError('Invalid type.')
    if (startChar.length !== 1 || endChar.length !== 1) throw new TypeError('Must be one char.')
    if (escapeChars.length < 1) throw new TypeError('Must be one or more chars.')
    const C = StrForRegExp
    const allow = `${endChar}${C.any(escapeChars)}`
    const char = C.or(C.not(endChar), allow)
    const start = C.excludeAfter(startChar, escapeChars)
    const end = C.excludeAfter(endChar, escapeChars)
    return `${start}${C.repeatOrEmpty(char)}${end}`
  }
  static bundleEscape(escapeChar) {
    if (typeof(escapeChar) !== 'string')
      throw new TypeError('Invalid type.')
    if (escapeChar.length !== 1)
      throw new TypeError('Must be one char.')
    return StrForRegExp.excludeAfter(escapeChar, escapeChar)
  }
}
